import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Lottie from "react-lottie-player"
import loading from "../../../../assets/lottie_files/notFoundAnimation.json"
import ResourcesCardForUpload from '../../../../components/cards/resourceCard/ResourcesCardForUpload'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography } from "@material-tailwind/react"
import ResourcesEditModal from '../../../../components/modal/resources_edit/ResourcesEditModal'

const ResourcesContainer = () => {

    const [branchList, setBranchList] = useState([])
    const [resourcesList, setResourcesList] = useState([])
    const [schemeList, setSchemeList] = useState([])
    const [semesterList, setSemesterList] = useState([])
    const [statusList, setStatusList] = useState([
        { name: "pending", value: "pending" },
        { name: "approved", value: "approved" },
        { name: "reject", value: "reject" }
    ])
    const [visibilityList, setVisibilityList] = useState([
        { name: "visible", value: "true" },
        { name: "invisible", value: "false" }
    ])

    const [branch, setBranch] = useState("All")
    const [scheme, setScheme] = useState("All")
    const [semester, setSemester] = useState("All")
    const [status, setStatus] = useState("All")
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    const [firstLoad, setFirstLoad] = useState(false)

    const [open, setOpen] = useState(false)
    const [loadAgain, setLoadAgain] = React.useState(false)

    const [editName, setEditName] = useState("")
    const [editType, setEditType] = useState("")
    const [editId, setEditId] = useState("")

    let sortOptions = { createdAt: -1 }


    const handleOpen = (name, type, id) => {
        setOpen(!open)
        console.log(name)

        setEditName(name)
        setEditType(type)
        setEditId(id)
    }




    const fetchBranches = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branches`
            )
            setBranchList(result.data)
        }
        catch (e) {
        }
    }

    const fetchAllResources = async () => {
        try {
            const data = {
                branch: branch,
                scheme: scheme,
                semester: semester,
                approval: status
            }

            if (data.branch == "All") {
                delete data.branch
            }

            if (data.scheme == "All") {
                delete data.scheme
            }

            if (data.semester == "All") {
                delete data.semester
            }

            if (data.approval == "All") {
                delete data.approval
            }

            let url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${1}&limit=${limit}&search=${JSON.stringify(data)}&sortOptions=${JSON.stringify(sortOptions)}`

            const res = await axios.get(url)
            setTotalRecords(res.data.totalRecords)
            setResourcesList(res.data.data)

        } catch (e) {
            setResourcesList([])
            setTotalRecords(0)
            setActive(0)
        }
    }


    const fetchMoreResources = async () => {
        try {
            setActive(active + 1)
            const data = {
                branch: branch,
                scheme: scheme,
                semester: semester,
                approval: status
            }

            if (data.branch == "All") {
                delete data.branch
            }

            if (data.scheme == "All") {
                delete data.scheme
            }

            if (data.semester == "All") {
                delete data.semester
            }

            if (data.approval == "All") {
                delete data.approval
            }



            let url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${active + 1}&limit=${limit}&search=${JSON.stringify(data)}&sortOptions=${JSON.stringify(sortOptions)}`

            const res = await axios.get(url)
            let newArr = resourcesList.concat(res.data.data)
            setResourcesList(newArr)

        } catch (e) {
            console.log(e)
        }
    }

    const setSubjects = (e) => {
        setActive(1)
        if (e.target.value != "") {
            let id = e.target.value
            try {
                let obj = branchList.find((val) => val._id === id)

                setSchemeList(obj.schemes)
                setBranch(id)
                setSemesterList(obj.semesters)
            }
            catch {
                setBranch(id)
            }

        }
    }

    const setSchemes = (e) => {
        setActive(1)
        if (e.target.value != "") {
            setScheme(e.target.value)
        }
    }

    const setSemesters = (e) => {
        setActive(1)
        if (e.target.value != "") {
            setSemester(e.target.value)
        }
    }

    const deleteFromList = (id) => {
        setResourcesList(resourcesList.filter((val) => val._id !== id));
        setTotalRecords(resourcesList.filter((val) => val._id !== id).length)
    };

    useEffect(() => {
        if (firstLoad) {
            if (branch != "" && scheme != "" && semester != "" && status != "") {
                setActive(1)
                fetchAllResources()
            }
        }
    }, [branch, scheme, semester, status])


    useEffect(() => {
        setActive(1)
        fetchAllResources()
        fetchBranches()
        setFirstLoad(true)
    }, [loadAgain])




    return (
        <div className='p-5 flex flex-col w-full h-full items-center'>

            <ResourcesEditModal open={open} handleOpen={handleOpen} editId={editId} loadAgain={loadAgain} setLoadAgain={setLoadAgain} editName={editName} editType={editType} setEditName={setEditName} setEditType={setEditType} />
            <div className='w-full flex justify-end mt-0 h-full'>
                <div className="w-full h-full flex flex-wrap gap-5 md:gap-10 my-5 justify-center items-center">
                    <select onChange={setSubjects} className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Branch</option>
                        {
                            branchList.map((val, key) => {
                                return <option key={key} value={val._id}>{val.branchName}</option>
                            })
                        }
                    </select>

                    <select onChange={setSchemes} className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Scheme</option>
                        {
                            schemeList.map((val, key) => {
                                return <option key={key} value={val}>{val}</option>
                            })
                        }
                    </select>

                    <select onChange={setSemesters} className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Semester</option>
                        {
                            semesterList.map((val, key) => {
                                return <option key={key} value={val}>{val}</option>
                            })
                        }
                    </select>

                    <select onChange={(e) => {
                        setActive(1)
                        if (e.target.value != "") {
                            setStatus(e.target.value)
                        }
                    }} className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Status</option>
                        {
                            statusList.map((val, key) => {
                                return <option key={key} value={val.value}>{val.name}</option>
                            })
                        }
                    </select>

                </div>
            </div>

            <Typography className=" flex justify-center capitalize mb-10 blue-gray mt-5 dark:text-[#e4e4e4] font-ligh ">Total : {totalRecords}</Typography>

            <InfiniteScroll
                dataLength={resourcesList.length}
                next={() => {
                    fetchMoreResources()
                }}
                hasMore={resourcesList.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-5'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <div className='w-full h-full flex flex-wrap gap-14 justify-center min-w-screen min-h-[50vh] pt-10 pb-24'>
                    {
                        (resourcesList.length === 0) ? <Lottie
                            loop
                            animationData={loading}
                            play
                            className="w-full min-h-[19rem] h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
                        /> :
                            resourcesList.map((data, key) => {
                                return (<ResourcesCardForUpload
                                    key={key}
                                    data={data}
                                    cardHeight={"10rem"}
                                    statusList={statusList}
                                    visibilityList={visibilityList}
                                    deleteFromList={deleteFromList}
                                    edit={handleOpen}
                                />)
                            })
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default ResourcesContainer
import React, { useEffect } from 'react'
import { Select, Option } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux"
import { StudyStateActionCreators } from '../../../../state/index';
import axios from 'axios';
import { useSelector } from "react-redux";

const Scheme = () => {
  const navigate = useNavigate()

  const [scheme, setScheme] = useState('')
  const [semester, setSemester] = useState('')
  const [branch, setBranch] = useState('')


  const [branchesList, setBranchesList] = React.useState([])
  const [semestersList, setSemestersList] = React.useState([])
  const [schemeList, setSchemeList] = React.useState([])

  const dispatch = useDispatch();
  const { setStudyState } = bindActionCreators(StudyStateActionCreators, dispatch)
  const theme = useSelector(state => state.theme)

  const setStateStudy = () => {

    if (scheme != "" && branch != "" && semester != "") {
      setStudyState(
        {
          scheme: scheme,
          semester: semester,
          branch: branch
        })
      navigate(`/pages/study/${branch}/${scheme}/${semester}`)
    }
  }


  const getConstant = async (path) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/${path}`)
      return res
    }
    catch (e) { console.log(e) }
  }

  const setConstants = async () => {
    try {
      let branchData = await getConstant("branches/branches");
      let branch = branchData.data.map((value) => {
        return value
      });
      console.log(branch)
      setBranchesList(branch)
    } catch { }
  }

  useEffect(() => {
    setConstants()
  }, [])

  return (
    <div className='bg-[#d4e0ff55] dark:bg-[#0f172a] flex flex-col gap-16 h-screen justify-center items-center pt-32'>
      <div className='flex flex-col gap-10'>
        <div className="w-72 dark:text-white">
          <Select required label="Branch" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setBranch(e)
            for (let item of branchesList) {
              if (item._id === e) {
                setSemestersList(item.semesters)
                console.log(item.semesters)
                break;
              }
            }

            for (let item of branchesList) {
              if (item._id === e) {
                setSchemeList(item.schemes)
                console.log(item.semesters)
                break;
              }
            }
          }} className='capitalize' >
            {branchesList.map((value, key) => <Option key={key} className='capitalize' value={value._id}>{value.branchName}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="Semester" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setSemester(e)
          }} className='capitalize' >
            {semestersList.map((value, key) => <Option className='capitalize' key={key} value={value}>{value}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="scheme" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setScheme(e)
          }} className='capitalize' >
            {schemeList.map((value, key) => <Option className='capitalize' key={key} value={value}>{value}</Option>)}
          </Select>
        </div>


      </div>


      <Button className='bg-[#3063F1]' onClick={() => setStateStudy()}><ArrowForwardIcon /></Button>

    </div>
  )
}

export default Scheme
import React from "react";
import Lottie from "react-lottie-player";
import { Button } from "@material-tailwind/react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ExploreLottie from "../../../assets/lottie_files/Explore.json";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Explore = () => {
  const studyState = useSelector(state => state.studyState)
  return (
    <div className="w-full flex flex-wrap justify-center flex-col-reverse lg:flex-row lg:gap-x-28 align-middle items-center md:px-14 py-10 lg:pr-40">
      <div className="md:px-10" data-aos="fade-right">
        <Lottie
          loop
          animationData={ExploreLottie}
          play
          className="w-full h-full lg:w-[28rem] lg:h-[28rem] xl:w-[36rem] xl:h-[36rem] z-0"
        />
      </div>

      <div className="flex justify-center gap-y-6 items-center flex-col text-center " data-aos="fade-left">
        <h1 className="font-BalooBhaina2 font-bold text-[1.4rem] md:text-[1.6rem] text-[#3063F1]">
          Explore Learning Hub
        </h1>
        <p className="font-base font-semibold tracking-wide font-BalooBhaina2 text-[1.4rem]  md:text-[2.2rem] leading-relaxed dark:text-white">
          Notes, Study Materials, Videos,
          <br />Previous Year Papers, &  More
        </p>
        <Link to={studyState.branch != null && studyState.scheme != null && studyState.semester != null ? `/pages/study/${studyState.branch}/${studyState.scheme}/${studyState.semester}` : "/pages/scheme"}>
          <Button variant="text" className=" w-20 capitalize font-BalooBhaina2 ">
            <ArrowRightAltIcon fontSize="large" style={{ color: "3063F1" }} />
          </Button></Link>

      </div>
    </div>
  );
};

export default Explore;

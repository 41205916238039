import React from "react";
import { Route, Routes } from "react-router-dom";
import Study from "./study/Study";
import Scheme from "./scheme/Scheme";
import Navbar from "../../../components/navbars/app_home_navbar/Navbar";
import { useEffect } from 'react'
import Resources from "./resources/Resources";
import Subjects from "./subject/Subjects";
import About from "./about/About";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import TermOfUse from "./termofuse/TermOfUse";
import ContactUs from "./contact/ContactUs";


const PagesRouter = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <div className='w-full h-full overflow-hidden'>
      <Navbar />
      <Routes>
        <Route path="/scheme" element={<Scheme />} />
        <Route path="/study/:branch/:scheme/:semester" element={<Study />} />
        <Route path="/study/:branch/:scheme/:semester/:type" element={<Subjects />} />
        <Route path="/study/:branch/:scheme/:semester/:type/:subject" element={<Resources />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-use" element={<TermOfUse />} />
        <Route path="/contact-us" element={<ContactUs />} />

      </Routes>
    </div>

  );
};

export default PagesRouter;

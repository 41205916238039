import bhunesh from "../../assets/profile_pics/bhunesh.jpg"
import Dev from "../../assets/profile_pics/dev.jpg"
import Farhan from "../../assets/profile_pics/farhan.jpg"
import Mayur from "../../assets/profile_pics/mayur.jpg"
import Kaif from "../../assets/profile_pics/kaif.jpg"

const initialFeedbacks = {
    data: [
        {
            name: "Bhunesh suryavanshi",
            title: "Student (Sy. Co.)",
            stars: 5,
            img: bhunesh,
            description: "The smooth and easy navigation makes it incredibly convenient for students like me to access the information we need promptly. The simplicity of the interface enhances the overall user experience, allowing us to focus on our studies."
        },
        {
            name: "Md.Kaif Ansari",
            title: "Student (Ty. Co)",
            img: Kaif,
            stars: 5,
            description: "The interactive and user-friendly interface, coupled with a vibrant community, has made studying more enjoyable and collaborative. The plethora of educational content, study materials have undoubtedly enriched our learning journey."
        },
        {
            name: "Dev Bagul",
            title: "Student (Ty. Ce)",
            stars: 4,
            img: Dev,
            description: "I appreciate the comprehensive resource hub provided by MSBTEWallah. The availability of study materials, previous question papers, and other educational resources in one place significantly aids in exam preparation."
        },
        {
            name: "Farhan Pathan",
            title: "Student (Ty. co)",
            stars: 4,
            img: Farhan,
            description: "The inclusion of interactive features, such as forums or discussion boards, enhances collaboration among students. It creates a sense of community and provides a platform for sharing insights and asking questions."
        },
        {
            name: "Mayur Rajput",
            title: "Student (Fy. Co)",
            img: Mayur,
            stars: 3,
            description: "The timely updates and notifications feature is invaluable. It keeps students informed about important announcements, exam schedules, and any changes, contributing to better communication between the board and the students."
        }
    ]
}

const reducer = (state = initialFeedbacks, action) => {
    switch (action.type) {
        case 'addFeedback':
            return [...state, action.padyload]
        default:
            return state
    }
}

export default reducer
import React from 'react'
import StudyCards from '../../../../components/cards/studyCard/StudyCards'
import { useSelector } from "react-redux";
import StudyBreadcrumbs from '../study/StudyBreadcrumbs'
import Seo from '../../../../components/seo/Seo';
import { STUDY_PAGE as PAGE } from '../../../../constants/Seo';
import { useParams } from 'react-router-dom'
import GoogleAd from '../../../../components/seo/GoogleAd';

const Study = () => {
  const study = useSelector(state => state.study)
  const { branch, scheme, semester, type } = useParams()

  return (
    <div className='bg-[#d4e0ff55] dark:bg-[#0f172a] pt-20 xl:pt-32'>
      <Seo
        title={PAGE.title}
        description={PAGE.description}
        name={PAGE.name}
        keywords={PAGE.keywords}
        type={PAGE.type} />
      <StudyBreadcrumbs />
      <GoogleAd slot="6578695022" googleAdId='ca-pub-2140235140057175' />
      <div className='w-full h-full flex-wrap flex  py-10 xl:py-10 px-5 md:px-20 justify-center gap-x-20 gap-y-14 sm:gap-y-20'>

        {
          study.map((value, index) => {
            return (
              <StudyCards
                title={value.title}
                msg={value?.description[0]?.toUpperCase() + value?.description?.substring(1)}
                img={value.img}
                url={`/pages/study/${branch}/${scheme}/${semester}/${value.type.toLowerCase().replaceAll(" ", "_")}`}
                key={index} />
            )
          })
        }
      </div>

    </div>
  )
}

export default Study
import React from "react";
import {
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  MoonIcon
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { ThemeActionCreators, ProfileActionCreators, UserActionCreators, PwaInstallActionCreators } from "../../../state/index";
import { bindActionCreators } from "redux"
import { useEffect, useState } from "react";
import guesUserImg from '../../../assets/icons/guest-user.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAlert from "../../../hooks/useAlert";



const NavbarProfile = ({ isMenuOpen, setIsMenuOpen }) => {
  const navigate = useNavigate();
  let { showAlert } = useAlert();

  const profile = useSelector(state => state.profile)
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const pwaInstall = useSelector(state => state.pwaInstall)
  const dispatch = useDispatch();
  const { setTheme } = bindActionCreators(ThemeActionCreators, dispatch)
  const { setProfile, updateProfileLogin } = bindActionCreators(ProfileActionCreators, dispatch)
  const { setUser } = bindActionCreators(UserActionCreators, dispatch)
  const { setPwaInstall } = bindActionCreators(PwaInstallActionCreators, dispatch)


  const [deferredPrompt, setDeferredPrompt] = useState(null);

  let setThemeMode = () => {
    if (theme === true) {
      document.body.classList.add("dark")
      setTheme(false)
    }
    else {
      document.body.classList.remove("dark")
      setTheme(true)
    }
  }

  const logout = () => {
    setProfile()
    axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/logout`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${user.token}`
      }
    })
      .then(response => {
        setUser(null)
        setProfile()
        showAlert("green", "User logout", 5000)
      })
      .catch(error => {
        console.log(error)
        try {
          if (error.response.status) {
            setUser(null)
            setProfile()
            showAlert("green", "User logout", 5000)
          }
          else {
            showAlert("red", "Something went wrong try again.", 5000)
          }
        }
        catch (e) {
          showAlert("red", "Something went wrong try again.", 5000)
        }

      });
  }

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        setPwaInstall(!pwaInstall)
      }
      setDeferredPrompt(null);
    }
  };

  const handleFunction = (type) => {
    console.log("hii")
    if (type === "logout") {
      logout()
    }
    else if (type === "install") {
      console.log("hello")
      handleInstallClick()
    }
  }

  useEffect(() => {
    try {
      let obj = JSON.parse(localStorage.getItem("persist:my-app"))
      if (obj.user !== "null") {
        updateProfileLogin()
      }
    } catch (e) {

    }

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [])

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-start">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 md:ml-24 outline-none"
          data-aos="fade-down"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="tania andrew"
            referrerPolicy="no-referrer"
            loading="lazy"
            src={user == null ? guesUserImg : user.user.data.profilePic}
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform dark:text-white ${isMenuOpen ? "rotate-180" : ""
              }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1 mr-10 dark:bg-[#0f1115] outline-none border-none" >
        <MenuItem
          onClick={() => { setThemeMode() }}
          className={`flex items-center gap-2 rounded dark:hover:bg-[#ffffff]`}

        >
          {React.createElement(MoonIcon, {
            className: `h-4 w-4`,
            strokeWidth: 2,
          })}
          <Typography
            as="span"
            variant="small"
            className="font-normal"
            color="inherit"
          >
            Dark Mode
          </Typography>
        </MenuItem>


        {profile.map(({ label, icon, link, type }, key) => {
          return (

            <MenuItem
              onClick={type !== "" ? () => handleFunction(type) : () => navigate(link)}
              className={`flex items-center gap-2 rounded dark:hover:bg-[#ffffff] ${type === "install" ? pwaInstall ? "hidden" : "" : ""}`}
              key={key}
            >

              {React.createElement(icon, {
                className: `h-4 w-4`,
                strokeWidth: 2,
              })}

              <Typography
                as="span"
                variant="small"
                className="font-normal"
              >
                {label}
              </Typography>

            </MenuItem>

          );
        })}



      </MenuList>
    </Menu>
  );
}

export default NavbarProfile
import React from 'react'
import useCurrentPage from '../../../../hooks/useCurrentPage'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react"
import ResourcesContainer from './ResourcesContainer'
import { useState } from 'react'
import axios from 'axios'
import useAlert from "../../../../hooks/useAlert"


const Resources = () => {

    const { showAlert } = useAlert();
    const [cards, setCards] = useState([]);
    let { setCurrentPageName } = useCurrentPage();
    const [activeTab, setActiveTab] = React.useState("myUploads");

    const fetchBranches = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branches`
            )

            console.log(result)
            setCards(result.data)
        }
        catch (e) {
            showAlert("red", "something went wrong", 5000)
        }

    }

    const data = [
        {
            label: "Uploaded Resources",
            value: "myUploads",
            desc: <ResourcesContainer cards={cards} />
        }
    ];


    React.useEffect(() => {
        setCurrentPageName("Resources")
        fetchBranches()
    }, [])
    return (
        <div className='cpBg dark:bg-[#0f1115] border-none py-5'>
            <Tabs value={activeTab}>
                <TabsHeader
                    className="rounded-none border-b hidden dark:bg-[#0f1115] border-blue-gray-50 bg-transparent felx justify-center items-center p-0"
                    indicatorProps={{
                        className:
                            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none dark:bg-[#0f1115]",
                    }}
                >
                    {data.map(({ label, value }) => (
                        <Tab
                            key={value}
                            value={value}
                            onClick={() => setActiveTab(value)}
                            className={activeTab === value ? "text-gray-900 dark:bg-[#0f1115] dark:text-white pb-5" : "dark:bg-[#0f1115] dark:text-[#ffffff8d] pb-5"}
                        >
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, desc }) => (
                        <TabPanel key={value} value={value} className='flex px-0 justify-center items-center'>
                            {desc}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </div>
    )
}

export default Resources
let option = {
    scheme:null,
    semester:null,

}
const reducer = (state=option, action) =>{
    switch(action.type)
    {
        case 'setStudyState':
            return action.payload
        default:
            return state
    }
}

export default reducer
import React, { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import axios from 'axios'
import loading from "../../../assets/lottie_files/loading.json"
import notFoundAnimation from "../../../assets/lottie_files/notFoundAnimation.json"
import Lottie from "react-lottie-player"
import ResourceCardWithoutDelete from '../../../components/cards/resourceCard/ResourceCardWithoutDelete'
import useCurrentPage from '../../../hooks/useCurrentPage'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography } from "@material-tailwind/react"

const Favourites = () => {

    const { getUserInfo } = useAuth()

    const { setCurrentPageName } = useCurrentPage()

    const [resourcesList, setResourcesList] = useState([])
    const [animation, setAnimation] = useState(true)
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(6)
    const [totalRecords, setTotalRecords] = useState(0)


    const fetchSaved = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/saved/?page=${active}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            console.log(result)

            setResourcesList(result.data.data)
            setTotalRecords(result.data.totalRecords)
            if (result.data.data.length === 0) {
                setAnimation(false)
            }
        }
        catch (e) {
            setAnimation(false)
        }

    }

    const fetchMore = async () => {
        try {
            setActive(active + 1)
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/saved/?page=${active + 1}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            console.log(result)

            setResourcesList(resourcesList.concat(result.data.data))
        }
        catch (e) {
        }

    }

    useEffect(() => {
        fetchSaved()
        setCurrentPageName("Favourites")
    }, [])


    return (
        <div className='flex justify-center items-center gap-12 flex-col flex-wrap cpBg p-5'>
            <Typography className=" flex justify-center capitalize blue-gray dark:text-[#e4e4e4] font-ligh w-full mt-6">Total : {totalRecords}</Typography>
            <InfiniteScroll
                dataLength={resourcesList.length}
                next={() => {
                    fetchMore()
                }}
                hasMore={resourcesList.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <div className='w-full flex-wrap flex  py-8 xl:py-14 px-5 md:px-5 justify-center  gap-x-10 gap-y-10 '>
                    {
                        resourcesList.length == 0 ?
                            <>
                                <Lottie
                                    loop
                                    animationData={loading}
                                    play={true}
                                    className={`${animation ? "block" : "hidden"} w-full h-[45vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                                />
                                <Lottie
                                    loop
                                    animationData={notFoundAnimation}
                                    play={true}
                                    className={`${animation ? "hidden" : "block"} w-full h-[45vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                                />

                            </>
                            :
                            (
                                resourcesList.map((value, key) => {
                                    return (
                                        <ResourceCardWithoutDelete
                                            key={key}
                                            color={`dark:bg-[#0f172a] bg-[#f1f2f3]`}
                                            id={value?.resource?._id}
                                            resourceName={value?.resource?.resourceName}
                                            subject={value?.resource?.subject?.name}
                                            branch={value?.resource?.branch?.branchName}
                                            semester={value?.resource?.semester}
                                            scheme={value?.resource?.scheme}
                                            createdAt={value?.resource?.createdAt}
                                            userEmail={value?.resource?.userEmail}
                                            profilePic={value?.resource?.profilePic}
                                            likes={value?.resource?.likes}
                                            views={value?.resource?.views}
                                            path={value?.resource?.type != "videoLecture" ? "/auth/document-viewer/" + value?.resource?.path.replaceAll("/", ",") : 'https://www.youtube.com/playlist?list=' + value?.resource?.playList[0]}
                                            type={value?.resource?.type}
                                            fav={true}
                                        />
                                    )
                                })
                            )
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default Favourites
import React from 'react'
import Lottie from "react-lottie-player";
import communityLottie from "../../../assets/lottie_files/communitySection.json"
import { Button } from "@material-tailwind/react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';

const CommunitySection = () => {
  return (
    <div className='w-full flex flex-wrap justify-center  align-middle items-center md:px-14 py-10'    >
      <div className='flex justify-center items-center flex-col text-center gap-5 ' data-aos="fade-right">
        <h1 className='font-BalooBhaina2 font-bold text-[1.4rem] md:text-[1.6rem] text-[#3063F1]'>
          Join Our Community
        </h1>
        <p className='font-base font-semibold tracking-wide font-BalooBhaina2 text-[1.4rem] md:text-[2.2rem] leading-relaxed dark:text-white'>
          Maharashtra's Premier Social
          <br /> Media Platform for <span className='text-[#3063F1]'>MSBTE</span> Students
        </p>
        <Link to="/auth/signUp"><Button variant="text" className=' w-24 capitalize font-BalooBhaina2 '><ArrowRightAltIcon fontSize="large" style={{ 'color': "3063F1" }} /></Button></Link>
      </div>
      <div className='md:px-10' data-aos="fade-left">
        <Lottie loop animationData={communityLottie} play className="w-full h-full lg:w-[28rem] lg:h-[28rem] xl:w-[36rem] xl:h-[36rem] z-0" />
      </div>
    </div>
  )
}

export default CommunitySection
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Tooltip,
  } from "@material-tailwind/react";
  import CircularImg from '../../../components/image_container/CircularImg' 
  import guestUser from "../../../assets/icons/guest-user.png"
   
  const ProfileCard = ({src = guestUser, fullName = "      ", type = "     "}) => {
    return (
      <Card className="w-96 bg-transparent shadow-none flex flex-col justify-center">
        <CardHeader floated={false} className="h-[22rem] p-10 bg-transparent shadow-none">
          <CircularImg src={src}/>
        </CardHeader>
        <CardBody className="text-center flex flex-col justify-center items-center">
          <Typography variant="h4" color="blue-gray" className="mb-2 dark:text-white capitalize">
            {fullName}
          </Typography>
          <Typography color="blue-gray" className="font-medium dark:text-white capitalize" textGradient>
            {type}
          </Typography>
        </CardBody>
      </Card>
    );
  }
export default ProfileCard
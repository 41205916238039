export default function swDev() {
    let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    navigator.serviceWorker.register(swUrl)
        .then((response) => {
            // console.warn("result : ", response)
        })
        .catch((e) => {
            // console.error(e)
        })
        
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, { scope: "/firebase-cloud-messaging-push-scope" })
}
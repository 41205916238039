// BlogRouter.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Blogs from "./pages/Blogs";
import Navbar from "../../components/navbars/app_home_navbar/Navbar";
import Post from "./pages/Post";
import Footer from "../app/home/Footer";


const BlogRouter = () => {
  return (
    <>
      <div className='w-full h-full overflow-hidden bg-[#d4e0ff55] dark:bg-[#0f172a] '>
        <Navbar />
        <div className="flex w-full justify-between overflow-hidden  dark:bg-[#0f172a] px-5 xl:px-40">
          <div className="w-full flex border-black  dark:bg-[#0f172a] ">
            <Routes>
              <Route path="/" element={<Blogs />} />
              <Route path="/:slug" element={<Post />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogRouter;

import React, { useEffect, useState } from 'react'
import StudyCards from '../../../../components/cards/studyCard/StudyCards'
import { useSelector } from "react-redux";
import SubjectBreadcrumbs from '../subject/SubjectBreadcrumbs'
import axios from 'axios';
import { useParams } from 'react-router-dom'
import Lottie from "react-lottie-player";
import loading from "../../../../assets/lottie_files/loading.json"
import notFoundAnimation from "../../../../assets/lottie_files/notFoundAnimation.json"
import Seo from '../../../../components/seo/Seo';
import { NOTES_PAGE, SYLLABUS_PAGE, SOLVED_MANUAL_PAGE, PREVIOS_YEAR_PAGE, PREVIOS_YEAR_MODEL_ANSWER_PAGE, VIDEO_LECTURE_PAGE } from '../../../../constants/Seo';
import GoogleAd from '../../../../components/seo/GoogleAd';


const Subjects = () => {

    const { branch, scheme, semester, type } = useParams()

    const [animation, setAnimation] = useState(true)
    const [subjectsList, setSubjectsList] = useState([])
    const [PAGE, setPAGE] = useState({})

    const subjects = async (scheme, semester, branch) => {
        try {
            console.log(scheme)
            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/subjects`,
                {
                    params: {
                        semester: semester,
                        branch: branch,
                        scheme: scheme,
                        limit: "all",
                        page: "all"
                    }
                })
            console.log(res)
            setSubjectsList(res.data)
            if (res.data.length === 0) {
                setAnimation(false)
            }
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        if (type == "notes") {
            setPAGE(NOTES_PAGE)
        }
        else if (type == "syllabus") {
            setPAGE(SYLLABUS_PAGE)
        }
        else if (type == "syllabus") {
            setPAGE(SYLLABUS_PAGE)
        }
        else if (type == "video_lecture") {
            setPAGE(VIDEO_LECTURE_PAGE)
        }
        else if (type == "solved_manual") {
            setPAGE(SOLVED_MANUAL_PAGE)
        }
        else if (type == "previous_year_papes") {
            setPAGE(PREVIOS_YEAR_PAGE)
        }
        else if (type == "previous_year_model_answers") {
            setPAGE(PREVIOS_YEAR_MODEL_ANSWER_PAGE)
        }
        subjects(scheme, semester, branch)
    }, [])

    return (
        <div className='bg-[#d4e0ff55] dark:bg-[#0f172a] pt-20  xl:pt-32'>
            <Seo
                title={PAGE.title}
                description={PAGE.description}
                name={PAGE.name}
                keywords={PAGE.keywords}
                type={PAGE.type} />
            <SubjectBreadcrumbs backLink={`/pages/study/${branch}/${scheme}/${semester}`} />
            <GoogleAd slot="6578695022" googleAdId='ca-pub-2140235140057175' />
            <div className='w-full min-h-[100vh] max-h-full flex-wrap flex py-10 xl:py-10 px-5 md:px-20 justify-center gap-x-20 gap-y-14 sm:gap-y-20'>
                {
                    subjectsList.length === 0 ?
                        <>
                            <Lottie
                                loop
                                animationData={loading}
                                play={true}
                                className={`${animation ? "block" : "hidden"} w-full h-[50vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                            />
                            <Lottie
                                loop
                                animationData={notFoundAnimation}
                                play={true}
                                className={`${animation ? "hidden" : "block"} w-full h-[50vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                            />

                        </>
                        :
                        subjectsList
                            .map((value, index) => (
                                <StudyCards
                                    title={value?.name}
                                    msg={value?.code?.toUpperCase()}
                                    img={""}
                                    url={`/pages/study/${branch}/${scheme}/${semester}/${type}/${value._id}`}
                                    key={index}
                                />
                            ))
                }

            </div>
        </div>
    )
}

export default Subjects
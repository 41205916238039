import useCurrentPage from '../../../../hooks/useCurrentPage'
import useAuth from '../../../../hooks/useAuth'
import axios from 'axios'
import Lottie from "react-lottie-player";
import loading from "../../../../assets/lottie_files/notFoundAnimation.json";
import UsersCard from '../../../../components/cards/usersCard/UsersCard';
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography } from "@material-tailwind/react"
import React, { useEffect, useState } from 'react';

const UsersList = () => {
  let { setCurrentPageName } = useCurrentPage()
  let { getUserInfo } = useAuth()

  const [users, setUsers] = useState([])
  const [active, setActive] = useState(1)
  const [limit, setLimit] = useState(6)
  const [totalRecords, setTotalRecords] = useState(0)

  const fetchUsers = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/all?page=${active}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${getUserInfo().token}`
          }
        }
      )

      setTotalRecords(result.data.totalRecords)
      setUsers(result.data.data)
    }
    catch (e) {
      console.log(e)
    }
  }

  const fetchMoreUsers = async () => {
    try {
      setActive(active + 1)
      const result = await axios.get(
        `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/all?page=${active + 1}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${getUserInfo().token}`
          }
        }
      )

      setUsers(users.concat(result.data.data))
    }
    catch (e) {
      console.log(e)
    }
  }


  React.useEffect(() => {
    setCurrentPageName("Users")
    fetchUsers()
  }, [])


  return (
    <div className=' flex gap-12 flex-col flex-wrap cpBg p-5'>
      <Typography className=" flex justify-center capitalize blue-gray dark:text-[#e4e4e4] font-ligh pt-5">Total : {totalRecords}</Typography>

      <InfiniteScroll
        dataLength={users.length}
        next={() => {
          fetchMoreUsers()
        }}
        hasMore={users.length !== totalRecords}
        loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-5'>
          <Spinner color="blue" className='w-16 h-16' />
        </div>}
      >
        <div className='flex justify-center items-center gap-12 flex-wrap pt-5 py-14'>
          {
            (users.length === 0) ? <Lottie
              loop
              animationData={loading}
              play
              className="w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0 "
            /> :
              users.map((data) => {
                return (
                  <UsersCard
                    id={data._id} fullName={data.fullName} email={data.email} role={data.role} profilePic={data.profilePic} createdAt={data.createdAt}
                  />
                )
              })
          }
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default UsersList
import React from "react";
import {
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

function MobileSideBarMenuItem({ name, svg, link, closeDrawer }) {
  return (
    <Link to={link} onClick={closeDrawer}>
      <ListItem className="focus:bg-white active:bg-white text-black dark:text-white">
        <ListItemPrefix>{svg}</ListItemPrefix>
        {name}
      </ListItem>
    </Link>

  );
}

export default MobileSideBarMenuItem;

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import Lottie from "react-lottie-player"
import loading from "../../../assets/lottie_files/notFoundAnimation.json"
import BlogCard from '../../../components/cards/blogCard/BlogCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography, Input, Button } from "@material-tailwind/react"
import BlogEditModel from '../../../components/modal/blog/BlogEditModel'
import { useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import BlogArticalCard from '../../../components/cards/blogCard/BlogArticalCard'
import Seo from '../../../components/seo/Seo'
import { HOME_PAGE } from '../../../constants/Seo'

const Blogs = () => {

    const theme = useSelector(state => state.theme)

    const [resourcesList, setResourcesList] = useState([])
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    const [firstLoad, setFirstLoad] = useState(false)
    const [search, setSearch] = useState("")

    let sortOptions = useMemo(() => { return { createdAt: -1 } })

    const fetchAllResources = async (blogSearch) => {
        try {
            let url;
            if (blogSearch) {
                url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/search?title=${search}&sortOptions=${JSON.stringify(sortOptions)}`
            } else {
                url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/?page=${1}&limit=${limit}&sortOptions=${JSON.stringify(sortOptions)}`
            }

            const res = await axios.get(url)
            setTotalRecords(res.data.totalRecords)
            setResourcesList(res.data.data)

        } catch (e) {
            setResourcesList([])
            setTotalRecords(0)
            setActive(0)
        }
    }


    const fetchMoreResources = async (blogSearch) => {
        try {
            setActive(active + 1)

            let url;

            if (blogSearch) {
                url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/search?title=${search}&sortOptions=${JSON.stringify(sortOptions)}`
            }
            {
                url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/?page=${active + 1}&limit=${limit}&sortOptions=${JSON.stringify(sortOptions)}`
            }

            const res = await axios.get(url)
            let newArr = resourcesList.concat(res.data.data)
            setResourcesList(newArr)

        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        setActive(1)
        fetchAllResources(false)
        setFirstLoad(true)
    }, [])



    return (
        <div className=' dark:text-white w-full h-full min-h-screen pt-20 xl:pt-32 scrollable-div '>
            <Seo
                title={HOME_PAGE.title}
                description={HOME_PAGE.description}
                name={HOME_PAGE.name}
                keywords={HOME_PAGE.keywords}
                type={HOME_PAGE.type} />
            <div className='w-full flex justify-center  flex-row px-10'>
                <div className='flex gap-5 '>
                    <div>
                        <Input required size='lg' type='text' value={search} onChange={(e) => setSearch(e.target.value)} className='w-full w-50 h-full' label="Search" autoComplete='off' color={theme ? `gray` : 'white'} />
                    </div>
                    <Button size='sm' onClick={() => {
                        setActive(1)
                        fetchAllResources(true)
                    }} className='w-full' variant="outlined" color={theme ? `gray` : 'white'}><SearchIcon /></Button>
                </div>
            </div>
            <InfiniteScroll
                dataLength={resourcesList.length}
                next={() => {
                    fetchMoreResources(false)
                }}
                hasMore={resourcesList.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-5'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <div className='w-full h-full flex flex-wrap gap-14 justify-evenly min-w-screen min-h-[50vh] pt-10 pb-24 md:px-12'>
                    {
                        (resourcesList.length === 0) ? <Lottie
                            loop
                            animationData={loading}
                            play
                            className="w-full min-h-[19rem] h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
                        /> :
                            resourcesList.map((data, key) => {
                                return (<BlogArticalCard
                                    key={key}
                                    data={data}
                                />)
                            })
                    }

                </div>
            </InfiniteScroll>

        </div>
    )
}

export default Blogs
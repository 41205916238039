export const setProfile = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: 'setProfile',
            payload: data
        })
    }
}

export const updateProfileLogin = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: 'updateProfileLogin',
            payload: data
        })
    }
}


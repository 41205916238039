const option = {
    color:"red",
    msg:"",
    open:false
}

const reducer = (state=option, action) =>{
    switch(action.type)
    {
        case 'setAlert':
            return action.payload
        default:
            return state
    }
}

export default reducer
import React, { useEffect, useState } from 'react'
import { Route, Routes } from "react-router-dom"
import DashboardProfile from './profile/DashboardProfile'
import DashboardSidebar from '../../components/sidebar/dashboard_sidebar/DashboardSidebar'
import DashboardNavbar from '../../components/navbars/dashboard_navbar/DashboardNavbar'
import Uploads from './uploads/Uploads'
import '../../assets/styles/dashboard/dashboard.css'
import useAuth from '../../hooks/useAuth'
import AddBranch from './settings/branches/AddBranch'
import AddSubject from './settings/subjects/AddSubject'
import UsersList from './settings/users/UsersList'
import Resources from './settings/resources/Resources'
import Favourites from './favourites/Favourites'
import Seo from '../../components/seo/Seo'
import { DASHBOARD_PAGE } from "../../constants/Seo"
import AddBlog from './blog/AddBlog'

const DashboardRouter = () => {

  let { checkSession } = useAuth()

  let [sidebar, setSidebar] = useState(true)

  const handleSidebar = () => {
    if (sidebar) {
      setSidebar(false)
    }
    else {
      setSidebar(true)
    }
  }

  useEffect(() => {
    checkSession()
  }, [])

  return (
    <>
      <div className='flex bg-[#f1f2f3] w-full h-full min-h-screen dark:bg-[#0f172a]'>
        <Seo
          title={DASHBOARD_PAGE.title}
          description={DASHBOARD_PAGE.description}
          name={DASHBOARD_PAGE.name}
          keywords={DASHBOARD_PAGE.keywords}
          type={DASHBOARD_PAGE.type} />
        <DashboardSidebar sidebar={sidebar} handleSidebar={handleSidebar} setSidebar={setSidebar} />
        <div className='w-full h-full overflow-hidden flex flex-col md:ml-[20rem] md:px-5 mt-5 md:py-5 min-h-screen'>
          <DashboardNavbar handleSidebar={handleSidebar} />
          <div className='w-full h-full'>
            <Routes>
              <Route path="/profile" element={<DashboardProfile />} />
            </Routes>
            <Routes>
              <Route path="/uploads">
                <Route index element={<Uploads />} />
              </Route>
              <Route path="/favourites" element={<Favourites />} />
              <Route path="/settings/addbranch" element={<AddBranch />} />
              <Route path="/settings/addsubject" element={<AddSubject />} />
              <Route path="/settings/users" element={<UsersList />} />
              <Route path="/settings/resoruces" element={<Resources />} />
              <Route path="/settings/blog" element={<AddBlog />} />
            </Routes>

          </div>

        </div>
      </div>
    </>
  );
};

export default DashboardRouter;

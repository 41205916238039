import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Button
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  UserCircleIcon,
  BookmarkIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import { ThemeActionCreators, ProfileActionCreators, UserActionCreators } from "../../../state/index";
import useAlert from "../../../hooks/useAlert";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from "redux"
import { useEffect, useRef } from "react";
import useAuth from "../../../hooks/useAuth";

const DashboardSidebar = ({ sidebar, handleSidebar, setSidebar }) => {

  const navigate = useNavigate()
  const dropDrownRef = useRef(null)
  const text = useRef(null)
  const icon = useRef(null)

  const profile = useSelector(state => state.profile)
  const theme = useSelector(state => state.theme)
  const { getUserInfo } = useAuth()

  const dispatch = useDispatch();
  const { setTheme } = bindActionCreators(ThemeActionCreators, dispatch)
  const { setProfile, updateProfileLogin } = bindActionCreators(ProfileActionCreators, dispatch)
  const { setUser } = bindActionCreators(UserActionCreators, dispatch)
  let { showAlert } = useAlert();

  const [open, setOpen] = React.useState(0);
  const [dropDown, setDropDown] = useState(false)

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    if (theme == false) {
      if (dropDown) {
        dropDrownRef.current.style.background = "#0f1115"
        setDropDown(false)
      }
      else {
        dropDrownRef.current.style.background = "white"
        text.current.style.color = "black"
        icon.current.style.color = "black"
        setDropDown(true)
      }
    }
  };

  const logout = () => {
    setProfile()
    axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/logout`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${getUserInfo().token}`
      }
    })
      .then(response => {
        setUser(null)
        setProfile()
        showAlert("green", "User logout", 5000)
        setTimeout(() => {
          navigate("/")
        }, 200)
      })
      .catch(error => {
        console.log(error)
        try {
          if (error.response.status) {

            setUser(null)
            setProfile()
            showAlert("green", "User logout", 5000)
            setTimeout(() => {
              navigate("/")
            }, 200)
          }
          else {
            showAlert("red", "Something went wrong try again.", 5000)
          }
        }
        catch (e) {
          showAlert("red", "Something went wrong try again.", 5000)
        }

      });
  }



  return (
    <Card className={` md:dark:bg-[#0f1115] bgSidebar z-50 md:bg-[#ffffff] bg-[#7597f317] h-screen overflow-auto md:h-[calc(100vh-2rem)] fixed w-full md:max-w-[19rem]  p-4 md:ml-5 md:my-5 shadow-xl  shadow-blue-gray-900/5  md:block ${sidebar ? "hidden" : ""} z-50`}>
      <div className="md:mb-2 mt-4 md:p-4 p-4 pb-1 flex justify-between">
        <Link to="/">
          <Typography variant="h5" color="blue-gray" className="dark:text-white cursor-pointer">
            Msbte Wallah
          </Typography>
        </Link>
        <div className="md:hidden bg-transparent text-black dark:text-white" onClick={handleSidebar}>
          <CloseIcon />
        </div>
      </div>
      <List>

        <hr className="mb-5 my-2 border-blue-gray-50" />

        <Link to="/dashboard/profile" onClick={() => setSidebar(true)}>
          <ListItem className="dark:text-white darkHover" >
            <ListItemPrefix>
              <UserCircleIcon className="h-5 w-5" />
            </ListItemPrefix>
            Profile
          </ListItem>
        </Link>

        <Link to="/dashboard/uploads" onClick={() => setSidebar(true)}>
          <ListItem className="dark:text-white darkHover">
            <ListItemPrefix>
              <ArrowUpTrayIcon className="h-5 w-5" />
            </ListItemPrefix>
            Uploads
          </ListItem>
        </Link>

        <Link to="/dashboard/favourites" onClick={() => setSidebar(true)}>
          <ListItem className="dark:text-white darkHover">
            <ListItemPrefix>
              <BookmarkIcon className="h-5 w-5" />
            </ListItemPrefix>
            Favourites
          </ListItem>
        </Link>

        {
          (getUserInfo()?.user?.role == "admin") ?

            <Accordion
              open={open === 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                />
              }
            >
              <ListItem className="p-0 " selected={open === 1}
                ref={dropDrownRef}
                onMouseEnter={(e) => {
                  if (theme == false) {
                    text.current.style.color = "black"
                    icon.current.style.color = "black"
                  }
                }}
                onMouseOut={(e) => {
                  if (theme == false) {
                    if (dropDown == false) {
                      text.current.style.color = "white"
                      icon.current.style.color = "white"
                    }
                  }
                }}
              >



                <AccordionHeader
                  onMouseEnter={(e) => {
                    if (theme == false) {
                      text.current.style.color = "black"
                      icon.current.style.color = "black"
                    }
                  }}
                  onMouseOut={(e) => {
                    if (theme == false) {
                      if (dropDown == false) {
                        text.current.style.color = "white"
                        icon.current.style.color = "white"
                      }
                    }

                  }}
                  onClick={() => handleOpen(1)} className="border-b-0 p-3 rounded-md dark:hover:bg-white">
                  <ListItemPrefix>
                    <PresentationChartBarIcon ref={icon} className="h-5 w-5 dark:text-white" />
                  </ListItemPrefix>
                  <Typography ref={text} className="mr-auto font-normal dark:text-white">
                    Settings
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0 dark:text-white" onClick={() => setSidebar(true)}>
                  <Link to="/dashboard/settings/addbranch">
                    <ListItem>
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>

                      Add Branch
                    </ListItem>
                  </Link>

                  <Link to="/dashboard/settings/addsubject" onClick={() => setSidebar(true)}>
                    <ListItem>
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Add Subject
                    </ListItem>
                  </Link>


                  <Link to="/dashboard/settings/resoruces" onClick={() => setSidebar(true)}>
                    <ListItem>
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Resources
                    </ListItem>
                  </Link>


                  <Link to="/dashboard/settings/blog" onClick={() => setSidebar(true)}>
                    <ListItem>
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Blogs
                    </ListItem>
                  </Link>

                  <Link to="/dashboard/settings/users" onClick={() => setSidebar(true)}>
                    <ListItem>
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Users
                    </ListItem>
                  </Link>

                </List>

              </AccordionBody>
            </Accordion>

            :
            ""

        }


        <ListItem className="dark:text-white darkHover" onClick={() => { logout() }} >
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>

      </List>
    </Card >
  );
}

export default DashboardSidebar
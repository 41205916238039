import React from "react";
import lottieJson from "../../../assets/lottie_files/HomeHeader.json";
import Lottie from "react-lottie-player";


const Right = () => {
  return (
    <div className="mt-10 md:mt-0 h-full flex justify-center items-center md:px-36 lg:px-0 xl:pt-0">
      <Lottie loop animationData={lottieJson} play className="w-full h-full lg:w-[28rem] lg:h-[28rem] xl:w-[33rem] xl:h-[33rem] z-0" data-aos="fade-left" />
    </div>
  );
};

export default Right;

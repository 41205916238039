import {
  Card,
  CardBody,
  Typography
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const StudyCards = ({ title, msg, img, url }) => {
  return (
    <Link to={url}>
      <Card data-aos="fade-up" className="w-[19rem] cursor-pointer  md:w-80 h-[20rem] flex justify-center items-center border-b-[#3063F1] border-b-8 transition-all duration-300 hover:scale-105 dark:bg-[#0f1115]">
        <CardBody className="flex flex-col justify-center text-center capitalize align-middle flex-1 items-center gap-y-4 blue-gray dark:text-[#ffffff]">
          {img == "" ? "" : <img className="w-16 h-16" src={img} alt="img" />}
          <Typography variant="h5" className="mb-2">
            {title}
          </Typography>
          <Typography className="text-center blue-gray dark:text-[#e4e4e4] font-light">
            {msg}
          </Typography>
        </CardBody>
      </Card>
    </Link>
  );
}

export default StudyCards
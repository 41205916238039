import React from "react"
import IconImageCards from "../../../components/cards/IconImageCard/IconImageCards"
import right from '../../../assets/icons/right.png'
import medal from '../../../assets/icons/medal.png'
import newicon from '../../../assets/icons/newicon.png'
import books from '../../../assets/icons/books.png'
import notes from '../../../assets/icons/notes.png'
import videoCourses from '../../../assets/icons/videoCourses.png'
import IntoductionVideo from "./IntoductionVideo"
const LearnMoreSction = () => {
  let cardsData = [
    {
      title: "Curated Content",
      msg: "Learn in-depth conceptual overviews, how to approach an algorithm, how to implement it & how to optimize it.",
      img: right,
    },
    {
      title: "Learn from the best",
      msg: "Get access to lectures and get mentored by one of the best in the market.",
      img: medal,
    },
    {
      title: "Learn Something new",
      msg: "Learn new concepts daily, increase your productivity & reach your full potential.",
      img: newicon,
    },
    {
      title: "Notes & Study Material",
      msg: "Our meticulously crafted notes serve as your roadmap through the material, providing concise summaries and key takeaways.",
      img: books,
    },
    {
      title: "Old Exam Papers",
      msg: " Test your knowledge and track your progress with a collection of past exam papers. Familiarize yourself with the types of questions that may be asked.",
      img: notes,
    },
    {
      title: "Video Course Library",
      msg: "Visual learners rejoice! Our video courses offer dynamic explanations and walkthroughs, enhancing your understanding and retention, save your time.",
      img: videoCourses,
    }
  ];

  return (
    <div className="flex flex-col justify-between gap-y-10 px-5 py-20 items-center" data-aos="fade-down" >
      <div className="flex flex-col justify-between gap-y-4 items-center">
        <p className="text-xl lg:text-2xl font-bold leading-8 font-BalooBhaina2 text-[#3063F1] ">
          Why Msbte Wallah
        </p>
        <h1 className="text-2xl text-center px-2 font-BalooBhaina2 font-bold lg:text-3xl dark:text-white">
          Making learning easier and more convenient for you.
        </h1>
      </div>

      <IntoductionVideo />

      <div className="px-0 lg:px-24 flex flex-wrap sm:flex-grow gap-y-10 gap-5 lg:gap-16 xl:gap-16 justify-center ">

        {
          cardsData.map((value, index) => {
            return <IconImageCards key={index} title={value.title} msg={value.msg} img={value.img} />
          })
        }


      </div>
    </div>
  );
};

export default LearnMoreSction;

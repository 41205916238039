import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import ResourceBreadcrumbs from '../resources/ResourceBreadcrumbs'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Lottie from "react-lottie-player"
import ResourceCardWithoutDelete from '../../../../components/cards/resourceCard/ResourceCardWithoutDelete'
import loading from "../../../../assets/lottie_files/loading.json"
import notFoundAnimation from "../../../../assets/lottie_files/notFoundAnimation.json"
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from "@material-tailwind/react"
import useAuth from '../../../../hooks/useAuth'
import GoogleAd from '../../../../components/seo/GoogleAd';

const Resources = () => {

    const { branch, scheme, semester, type, subject } = useParams()
    const { getUserInfo } = useAuth()

    const [resourcesList, setResourcesList] = useState([])
    const [animation, setAnimation] = useState(true)
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    let sortOptions = { likes: -1 }

    const checkSame = async (resList) => {
        let rlist = resList
        if (getUserInfo() !== null) {
            let fav = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/saved/`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            fav = fav.data.data

            for (let i = 0; i < rlist.length; i++) {
                rlist[i].fav = false
            }


            for (let i = 0; i < fav.length; i++) {
                let favId = fav[i]?.resource?._id
                for (let j = 0; j < rlist.length; j++) {
                    if (rlist[j]._id === favId) {
                        rlist[j].fav = true
                    }
                }
            }


            return rlist
        }
        else {
            return rlist
        }
    }

    function capitalizeEachWord(str) {
        // Split the string into an array of words
        let words = str.split('_');

        // Capitalize each word
        let capitalizedWords = words.map((word, index) => {
            // Capitalize the first letter of each word
            if (index == 0) {
                return word
            }
            else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        });

        // Join the words back into a string
        let capitalizedString = capitalizedWords.join('');

        return capitalizedString;
    }


    const resources = async (scheme, semester, branch, subject, type) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${active}&limit=${limit}&search=${JSON.stringify({
                semester: semester,
                scheme: scheme,
                subject: subject,
                visible: true,
                approval: "approved",
                type: capitalizeEachWord(type)
            })}&sortOptions=${JSON.stringify(sortOptions)}`)

            const checkSameres = await checkSame(res.data.data)
            setResourcesList(checkSameres)
            setTotalRecords(res.data.totalRecords)

            if (res.data.length === 0) {
                setAnimation(false)
            }
        } catch (e) {
            setAnimation(false)
            console.log(e)
        }

    }

    const fetchMore = async (scheme, semester, branch, subject, type) => {
        try {
            setActive(active + 1)
            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${active + 1}&limit=${limit}&search=${JSON.stringify({
                semester: semester,
                scheme: scheme,
                subject: subject,
                visible: true,
                approval: "approved",
                type: capitalizeEachWord(type)
            })}&sortOptions=${JSON.stringify(sortOptions)}`)


            let newArr = resourcesList.concat(res.data.data)
            const checkSameres = await checkSame(newArr)
            setResourcesList(checkSameres)
        } catch (e) {

        }
    }


    useEffect(() => {
        resources(scheme, semester, branch, subject, type)
    }, [])




    return (
        <div className='bg-[#d4e0ff55] dark:bg-[#0f172a] pt-20  xl:pt-32 min-h-screen'>

            <ResourceBreadcrumbs backLink={`/pages/study/${branch}/${scheme}/${semester}`} backLink2={`/pages/study/${branch}/${scheme}/${semester}/${type}`} type={type} />
            <InfiniteScroll
                dataLength={resourcesList.length}
                next={() => {
                    fetchMore(scheme, semester, branch, subject, type)
                }}
                hasMore={resourcesList.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <GoogleAd slot="6578695022" googleAdId='ca-pub-2140235140057175' />
                <div className='w-full min-h-[50vh]  md:min-h-[80vh] max-h-full flex-wrap flex  py-10 mb-10 xl:py-14 px-5 md:px-20 justify-center gap-x-20 gap-y-14 sm:gap-y-20'>

                    {
                        resourcesList.length == 0 ?
                            <>
                                <Lottie
                                    loop
                                    animationData={loading}
                                    play={true}
                                    className={`${animation ? "block" : "hidden"} w-full h-[50vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                                />
                                <Lottie
                                    loop
                                    animationData={notFoundAnimation}
                                    play={true}
                                    className={`${animation ? "hidden" : "block"} w-full h-[50vh] sm:w-[40vh] md:w-[50vh] md:h-[50vh] lg:w-[50vh]  lg:h-[50vh] xl:w-[50rem] xl:h-[50vh] z-01`}
                                />

                            </>
                            :

                            resourcesList.map((value, index) => {
                                return (
                                    <ResourceCardWithoutDelete
                                        key={index}
                                        id={value._id}
                                        color={"dark:bg-[#0f1115]"}
                                        resourceName={value?.resourceName}
                                        subject={value.subject?.name}
                                        branch={value.branch?.branchName}
                                        semester={value?.semester}
                                        scheme={value?.scheme}
                                        createdAt={value?.createdAt}
                                        userEmail={value?.userEmail}
                                        profilePic={value?.profilePic}
                                        likes={value?.likes}
                                        views={value?.views}
                                        path={value?.type != "videoLecture" ? "/auth/document-viewer/" + value._id : 'https://www.youtube.com/playlist?list=' + value?.playList[0]}
                                        type={value.type}
                                        fav={value?.fav}
                                    />
                                )
                            })

                    }
                </div>

            </InfiniteScroll>
        </div >
    )
}

export default Resources
import React from "react"
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Spinner,
    Alert,
    Typography
} from "@material-tailwind/react"
import { useSelector } from "react-redux"
import useAuth from "../../../hooks/useAuth"
import { useState } from "react"
import axios from "axios"
import useAlert from "../../../hooks/useAlert"
import BlogWriter from "./BlogWriter"

const ResourcesEditModal = ({ open, handleOpen, loadAgain, setLoadAgain, editId, editTitle, setEditTitle, editCategory, setEditCategory, editContent, setEditContent, editSlug, setEditSlug, editDescription, setEditDescription, editKeywords, setEditKeywords, editFile, setEditFile }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert();

    const theme = useSelector(state => state.theme)
    const [send, setSend] = useState(false)
    const [alertBox, setAlertBox] = useState({
        visibility: "hidden",
        color: "red",
        text: ""
    })

    const setAlertForBox = async (message, color, visibility, time) => {

        setAlertBox({
            visibility: visibility,
            color: color,
            text: message
        })
        setTimeout(() => {
            setAlertBox({
                visibility: "hidden",
                color: "red",
                text: ""
            })
        }, time);
    }


    const update = async (e) => {
        e.preventDefault()
        try {
            if (send === false && editTitle && editContent && editCategory && editDescription && editKeywords && editFile != null) {
                setSend(true)

                const formData = new FormData()
                formData.append('file', editFile)

                const data = JSON.stringify({
                    title: editTitle,
                    content: editContent,
                    category: editCategory,
                    description: editDescription,
                    keywords: editKeywords,
                    slug: editSlug
                })

                formData.append('data', data)
                await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/${editId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserInfo().token}`
                        }
                    })
                showAlert("green", "Post Updated Successfully..!", 5000)
                setAlertForBox("Post Updated Successfully..!", "green", "block", 5000)
                setSend(false)
                setLoadAgain(!loadAgain)
            }
            else {
                setSend(true)

                const formData = new FormData()

                const data = JSON.stringify({
                    title: editTitle,
                    content: editContent,
                    category: editCategory,
                    description: editDescription,
                    keywords: editKeywords,
                    slug: editSlug
                })

                formData.append('data', data)
                await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/${editId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserInfo().token}`
                        }
                    })
                showAlert("green", "Post Updated Successfully..!", 5000)
                setAlertForBox("Post Updated Successfully..!", "green", "block", 5000)
                setSend(false)
                setLoadAgain(!loadAgain)
            }
        }
        catch (e) {
            setSend(false)
            if (e?.response?.data?.message) {
                console.log(e.response)
                showAlert("red", e.response.data.message, 5000)
                setAlertForBox(e.response.data.message, "red", 5000)
            } else {
                setAlertForBox("something went wrong...!", "red", "block", 5000)
                showAlert("red", "something went wrong...!", "block", 5000)
            }
        }
    }


    return (
        <>
            <Dialog open={open} size="xxl" className="md:px-3 md:py-3 theme z-30">
                <div className="flex items-center justify-between ">
                    <DialogHeader className="flex flex-col items-start text-theme">
                        <Typography className="md:mb-1 text-theme" variant="h4">
                            Edit
                        </Typography>
                    </DialogHeader>
                </div>
                <DialogBody className="overflow-y-scroll">

                    <BlogWriter update={update}
                        editTitle={editTitle}
                        setEditTitle={setEditTitle}
                        editCategory={editCategory}
                        setEditCategory={setEditCategory}
                        editContent={editContent}
                        setEditContent={setEditContent}
                        editSlug={editSlug}
                        setEditSlug={setEditSlug}
                        editDescription={editDescription}
                        setEditDescription={setEditDescription}
                        editKeywords={editKeywords}
                        setEditKeywords={setEditKeywords}
                        setFile={setEditFile}
                        file={editFile}
                    />
                    <Alert color={alertBox.color} className={`w-full md:w-2/5 ${alertBox.visibility}`}>{alertBox.text}</Alert>
                </DialogBody>
                <DialogFooter className="space-x-2 px-10 flex justify-end">

                    <Button variant="text" className="text-theme" onClick={() => handleOpen("", "", "", "", "", "", "", null)}>
                        cancel
                    </Button>
                    <Button className='bg-[#3063f1]' onClick={update} >
                        {send ? <Spinner className="h-4 w-8 text-white" /> : "Post"}
                    </Button>


                </DialogFooter>
            </Dialog >
        </>
    );
}

export default ResourcesEditModal
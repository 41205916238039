import { useParams } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState, useMemo } from 'react'
import { Typography, Spinner } from "@material-tailwind/react"
import Seo from '../../../components/seo/Seo'
import moment from 'moment'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const Post = () => {
    const { slug } = useParams()


    const [resourcesList, setResourcesList] = useState([])
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    const [firstLoad, setFirstLoad] = useState(false)

    let sortOptions = useMemo(() => { return { createdAt: -1 } })


    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }

    const addView = async (id) => {
        try {
            await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/add-view/${id}`)
        } catch (e) {

        }
    }

    const fetchAllResources = async () => {
        try {
            let url;

            url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/?search=${JSON.stringify({ slug })}&page=${1}&limit=${limit}&sortOptions=${JSON.stringify(sortOptions)}`
            const res = await axios.get(url)

            setTotalRecords(res.data.totalRecords)
            setResourcesList(res.data.data[0])

            addView(res.data.data[0]._id)
        } catch (e) {
            console.log(e)
            setResourcesList([])
            setTotalRecords(0)
            setActive(0)
        }
    }

    useEffect(() => {
        fetchAllResources()
    }, [])

    return (
        <>
            {
                resourcesList.length == 0 ? <div className=' flex justify-center items-center dark:text-white w-full h-full min-h-screen py-28 sm:px-10 xl:py-36 xl:px-52 scrollable-div'>
                    <Spinner color="blue" className='w-24 h-24' />
                </div> :
                    <div className={`  text-black dark:text-white w-full h-full min-h-screen py-28 sm:px-10 xl:py-36 xl:px-52 scrollable-div`}>
                        <Seo
                            title={resourcesList?.title}
                            description={resourcesList?.description}
                            keywords={resourcesList?.keywords}
                            name={resourcesList?.category}
                            type={resourcesList?.category} />
                        <Typography variant="h1" className='dark:text-white pt-5 text-4xl'>
                            {
                                resourcesList?.title
                            }
                        </Typography>
                        <div className='flex justify-end gap-5 py-10'>
                            <div>
                                {resourcesList.length === 0 ? "" : formatDate(resourcesList?.createdAt)}
                            </div>
                            <Typography variant="h6" className='dark:text-white uppercase'>
                                {
                                    resourcesList?.category
                                }
                            </Typography>


                        </div>
                        <div className='mb-20 h-[12rem] sm:h-[28rem]'>
                            {
                                resourcesList.length === 0 ?
                                    "" : <img
                                        className="h-full w-full rounded-lg object-cover object-center"
                                        src={resourcesList?.posterImg}
                                        alt={resourcesList?.title}
                                    />
                            }

                        </div>
                        <div dangerouslySetInnerHTML={{ __html: `${resourcesList?.content}` }} className='mb-14'>

                        </div>
                        <div className='flex justify-between'>
                            <Typography variant="h6" className='dark:text-white flex flex-row gap-5'>
                                <div className='flex gap-3 items-center '>
                                    <RemoveRedEyeIcon className='dark:text-white' />
                                </div>
                                {
                                    resourcesList?.views
                                }
                            </Typography>

                        </div>

                    </div>
            }
        </>
    )
}

export default Post
// BlogRouter.js
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Navbar from '../../../components/navbars/app_home_navbar/Navbar'
import Home from './Home';
import { useEffect } from 'react'
import NotFound from '../pages/NotFound/NotFound';


const HomeRouter = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <div className='w-full h-full overflow-hidden'>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default HomeRouter;

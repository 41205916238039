import React, { useEffect, useState } from 'react'
import { Input } from "@material-tailwind/react";
import ProfileCard from '../../../components/cards/profileCard/ProfileCard'
import { Button } from "@material-tailwind/react";
import useCurrentPage from '../../../hooks/useCurrentPage'
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import useAlert from "./../../../hooks/useAlert";

const DashboardProfile = () => {

  const { setCurrentPageName } = useCurrentPage()
  const { showAlert } = useAlert()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)

  const [data, setData] = useState();

  const {
    handleSubmit, watch, setValue, register
  } = useForm();

  const fullName = watch('fullName')
  const mobileNo = watch('mobileNo')
  const collegeName = watch('collegeName')
  const city = watch('city')
  const email = watch('email')

  const handleFormSubmit = async (data) => {
    try {
      const data = await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/update`,
        { fullName, mobileNo, collegeName, city },
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        })
      showAlert("green", "profile details updated successfully...!", 5000)
    }
    catch (e) {
      showAlert("red", "something went wrong...!", 5000)
    }

  }

  const fetchUserData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      })
      setData({ data, userImg: data.profilePic.replace("s96-c", "s400-c") })
      setValue("fullName", data.fullName)
      setValue("mobileNo", data.mobileNo)
      setValue("collegeName", data.collegeName)
      setValue("city", data.city)
      setValue("email", data.email)
    }
    catch (e) {
      console.log(e)
    }


  }

  useEffect(() => {
    setCurrentPageName("profile")
    fetchUserData()
  }, [])

  return (
    <div className=' flex flex-col items-center md:flex-row cpBg p-5'>
      <div className='md:border-r-2 md:border-[#455a64c8]'>
        <ProfileCard src={data?.userImg} fullName={fullName} type={data?.data?.role} />
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)} className='flex px-5 items-start h-full w-full flex-col gap-10 dark:text-white'>
        <Input size="lg" type='text' autoComplete='off' label="Full Name" color={theme ? `gray` : 'white'} {...register('fullName', { required: true })} />
        <Input size="lg" type='email' autoComplete='off' label="Email" color={theme ? `gray` : 'white'} readOnly {...register('email')} />
        <Input size="lg" type="tel" autoComplete='off' pattern="[0-9]*" maxLength="10" label="Mo. No" color={theme ? `gray` : 'white'} {...register('mobileNo', { required: true })} />
        <Input size="lg" type='text' autoComplete='off' label="College Name" color={theme ? `gray` : 'white'}  {...register('collegeName', { required: true })} />
        <Input size="lg" type='text' autoComplete='off' label="City" color={theme ? `gray` : 'white'} {...register('city', { required: true })} />
        <div className='flex justify-center md:justify-end w-full'>
          <Button className="capitalize px-7 bg-[#3063F1] text-sm" type='submit'>save</Button>
        </div>
      </form>
    </div>
  )
}

export default DashboardProfile
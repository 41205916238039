import React, { useEffect, useState } from 'react'
import ResourceCard from '../../../components/cards/resourceCard/ResourceCard'
import axios from 'axios';
import useAuth from '../../../hooks/useAuth'
import { Spinner, Typography } from "@material-tailwind/react"
import loadingAni from "../../../assets/lottie_files/loading.json"
import notFoundAnimation from "../../../assets/lottie_files/notFoundAnimation.json"
import Lottie from "react-lottie-player";
import InfiniteScroll from 'react-infinite-scroll-component'
import ResourcesEditModal from '../../../components/modal/resources_edit/ResourcesEditModal';

const UploadsContainer = ({ loadAgain, setLoadAgain }) => {
    const { getUserInfo } = useAuth()
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    const [cardData, setCardData] = useState([])
    const [animation, setAnimation] = useState(false)
    const [open, setOpen] = useState(false)

    const [editName, setEditName] = useState("")
    const [editType, setEditType] = useState("")
    const [editId, setEditId] = useState("")

    let search = { userId: getUserInfo().user.id, visible: true }
    let sortOptions = { createdAt: -1 }

    const handleOpen = (name, type, id) => {
        setOpen(!open)
        console.log(name)

        setEditName(name)
        setEditType(type)
        setEditId(id)
    }


    const fetchResources = async () => {
        try {
            setAnimation(true)
            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${1}&limit=${limit}&search=${JSON.stringify(search)}&sortOptions=${JSON.stringify(sortOptions)}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            setTotalRecords(res.data.totalRecords)
            setCardData(res.data.data)
            setAnimation(false)
        }
        catch (e) {
            setAnimation(false)
        }
    }


    const fetchMoreResources = async () => {
        try {
            setActive(active + 1)

            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resources?page=${active + 1}&limit=${limit}&search=${JSON.stringify(search)}&sortOptions=${JSON.stringify(sortOptions)}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })

            let newArr = cardData.concat(res.data.data)
            setCardData(newArr)
        }
        catch (e) {

        }

    }


    const deleteFromList = (id) => {
        setCardData(cardData.filter((val) => val._id !== id));
        setTotalRecords(cardData.filter((val) => val._id !== id).length)
    };


    useEffect(() => {
        console.log(cardData)
    }, [cardData])

    useEffect(() => {
        setActive(1)
        fetchResources()
    }, [loadAgain])

    return (
        <div className='flex h-full p-5 flex-col items-center gap-1  '>
            <Typography className=" flex justify-center capitalize blue-gray dark:text-[#e4e4e4] font-ligh w-full">Total : {totalRecords}</Typography>
            <ResourcesEditModal open={open} handleOpen={handleOpen} editId={editId} loadAgain={loadAgain} setLoadAgain={setLoadAgain} editName={editName} editType={editType} setEditName={setEditName} setEditType={setEditType} />
            <InfiniteScroll
                dataLength={cardData.length}
                next={() => {
                    fetchMoreResources()
                }}
                hasMore={cardData.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-8'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <div className='min-h-[50vh] w-full flex items-center gap-10 flex-wrap justify-center  pt-10 pb-10'>
                    {
                        cardData.length === 0 ?
                            <>
                                <Lottie
                                    loop
                                    animationData={loadingAni}
                                    play={true}
                                    className={`${animation ? "block" : "hidden"} w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0`}
                                />
                                <Lottie
                                    loop
                                    animationData={notFoundAnimation}
                                    play={true}
                                    className={`${animation ? "hidden" : "block"} w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0`}
                                />

                            </>
                            :
                            cardData.map((value, key) => {
                                // console.log(typeof (value?.playList))
                                return (
                                    <ResourceCard
                                        key={key}
                                        id={value?._id}
                                        resourceName={value?.resourceName?.length > 55 ? value?.resourceName.slice(0, 55) + "......" : value?.resourceName}
                                        subject={value.subject?.name}
                                        branch={value.branch?.branchName}
                                        semester={value?.semester}
                                        scheme={value?.scheme}
                                        createdAt={value?.createdAt}
                                        userEmail={value?.userEmail}
                                        profilePic={value?.profilePic}
                                        type={value?.type}
                                        approval={value?.approval}
                                        likes={value?.likes}
                                        views={value?.views}
                                        to={value?.type != "videoLecture" ? "/auth/document-viewer/" + value?._id : 'https://www.youtube.com/playlist?list=' + value?.playList[0]}
                                        deleteFromList={deleteFromList}
                                        edit={handleOpen}
                                    />
                                )
                            })
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default UploadsContainer
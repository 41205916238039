import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken, isSupported } from 'firebase/messaging';  // Include this line if you are using Firebase Cloud Messaging



const firebaseConfig = {
    apiKey: "AIzaSyAIkMa1OCnUhJ-c3JYLmWYjIMYuPS60dJs",
    authDomain: "msbtewallah.firebaseapp.com",
    projectId: "msbtewallah",
    storageBucket: "msbtewallah.appspot.com",
    messagingSenderId: "36658674404",
    appId: "1:36658674404:web:38179b4398b833bffa8af1",
    measurementId: "G-Q2GZ7070LK"
};


const app = initializeApp(firebaseConfig);
export let messaging
if (isSupported()) {
    messaging = getMessaging(app);
}


export const getFCMToken = async () => {
    return await getToken(messaging, { vapidKey: 'BBn6djqG1WyF7eLsz7-iBN81_kCFXIGQDipIiPauZs-Gg97F9Nbf4CaBAsiBKj80KmC4esa2n1R7ifIcPZshG-M' })
}


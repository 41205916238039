import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';



const FeedbackCard = ({ name, title, description, stars, img }) => {
  let star = []
  for (let i = 0; i < stars; i++) {
    star.push(<StarIcon style={{ color: "	#ffcf40" }} key={Math.random()} />)
  }
  if (stars !== 5) {
    for (let i = 0; i < (5 - stars); i++) {
      star.push(<StarBorderIcon style={{ color: "	#ffcf40" }} key={Math.random()} />)
    }
    // data-aos="fade"
  }
  return (
    <Card className="w-full xl:p-5 lg:h-[35rem] xl:h-[28rem] px-5 md:px-10  py-5 md:py-5 items-center  justify-center flex gap-2 dark:bg-[#0f1115]" >
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-0 flex justify-center items-center gap-4 pt-0 flex-col "
      >
        <Avatar
          size="lg"
          variant="circular"
          src={img}
          loading="lazy"
          alt="tania andrew"
        />
        <div className="flex w-full flex-col gap-0.5 ">
          <div className="flex items-center justify-between  flex-col gap-y-2 blue-gray dark:text-white">
            <Typography variant="h5" className="text-left">
              {name}
            </Typography>
            <div className="5 flex gap-0 text-left">
              {
                star.map((value) => {
                  return value
                })
              }
            </div>
          </div>
          <Typography className="text-center md:text-left blue-gray w-full flex justify-center dark:text-white">{title}</Typography>
        </div>
      </CardHeader>
      <CardBody className="h-72 py-0 sm:h-64 md:h-80 lg:h-80 xl:h-56 xl:px-8 text-left flex justify-center align-middle items-center px-5 dark:text-[#e4e4e4]">
        <Typography className="text-center leading-8">
          {description}
        </Typography>
      </CardBody>
    </Card>
  );
}

export default FeedbackCard
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk"
import reducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose storage engine

const persistConfig = {
    key: "my-app", // Storage key
    version: 1,
    storage,
    whitelist: ["theme", "studyState", "user", "Notification", "pwaInstall", "views", "privacy"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);


export default store
export { persistor }
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from "react-router-dom";

function StudyBreadcrumbs() {
  return (
    <Breadcrumbs className="bg-[#d4e0ff55] w-auto dark:bg-[#0f172a] dark:text-white ml-5 md:ml-24 xl:ml-32  ">
      <Link to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 dark:text-white "
          viewBox="0 0 20 20"
          fill="currentColor"

        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      </Link>
      <Link to="/pages/scheme">
        <span className="dark:text-white ">Scheme</span>
      </Link>
    </Breadcrumbs>
  );
}

export default StudyBreadcrumbs
export const HOME_PAGE = {
    title: "Msbte Wallah",
    description: "Empower MSBTE students with a comprehensive platform offering a wealth of study resources. Strive for excellence, access additional study materials, and enhance your understanding of technical subjects with MSBTE Wallah.",
    keywords: "MSBTE Wallah, MSBTE students, msbte model papers, study resources, exams, technical subjects, education, additional study materials, empower students, maharashtra state board of technical education, msbte notes, msbte model papers, msbte previous papers, msbte syllaubs, msbte video lectures, msbte study materials",
    name: "msbtewallah.in",
    type: "landing page"
}


export const ABOUT_PAGE = {
    title: "Msbte Wallah - (About)",
    description: "At MSBTE Wallah, our mission is to empower MSBTE students by providing a comprehensive platform that houses a wealth of study resources. We believe in the transformative power of education and its ability to open doors to a brighter future. Whether you're striving for excellence in exams, seeking additional study materials, or simply aiming to enhance your understanding of technical subjects, MSBTE Wallah is here to support you every step of the way.",
    name: "msbtewallah.in",
    keywords: "MSBTE Wallah, MSBTE students, msbte model papers, study resources, exams, technical subjects, education, additional study materials, empower students, maharashtra state board of technical education, msbte notes, msbte model papers, msbte previous papers, msbte syllaubs, msbte video lectures, msbte study materials",
    type: "page"
}

export const PRIVACY_PAGE = {
    title: "Msbte Wallah - (Privacy Policy)",
    description: "Welcome to MSBTE Wallah, your go-to educational platform for MSBTE study materials. We are committed to protecting your privacy and ensuring a secure and transparent experience while using our application. This Privacy Policy outlines how we collect, use, and safeguard your personal information.",
    keywords: "MSBTE Wallah, MSBTE students, msbte model papers, study resources, exams, technical subjects, education, additional study materials, empower students, maharashtra state board of technical education, msbte notes, msbte model papers, msbte previous papers, msbte syllaubs, msbte video lectures, msbte study materials",
    name: "msbtewallah.in",
    type: "page"
}


export const TERM_OF_USE_PAGE = {
    title: "Msbte Wallah - (Term Of Use)",
    description: "Welcome to MSBTE Wallah, a platform designed to enhance your learning experience. By using our application, you agree to comply with and be bound by the following terms and conditions. Please read the terms carefully before accessing or using MSBTE Wallah.",
    keywords: "MSBTE Wallah, MSBTE students, msbte model papers, study resources, exams, technical subjects, education, additional study materials, empower students, maharashtra state board of technical education, msbte notes, msbte model papers, msbte previous papers, msbte syllaubs, msbte video lectures, msbte study materials",
    name: "msbtewallah.in",
    type: "page"
}

export const STUDY_PAGE = {
    title: "Msbte Wallah - (Study)",
    description: "Unlock the power of education with MSBTE Wallah, your ultimate destination for comprehensive study resources tailored for MSBTE students. Dive into a wealth of materials covering technical subjects, exams, and additional study resources meticulously designed to empower students in their educational journey. Explore MSBTE notes, model papers, previous papers, syllabus details, and access engaging video lectures. Elevate your learning experience with MSBTE Wallah, dedicated to excellence in education for Maharashtra State Board of Technical Education students.",
    keywords: "MSBTE Wallah, MSBTE students, study resources, exams preparation, technical subjects, education materials, additional study materials, empower students, Maharashtra State Board of Technical Education, MSBTE notes, MSBTE model papers, MSBTE previous papers, MSBTE syllabus, video lectures, MSBTE study materials, technical education, MSBTE online, engineering studies, diploma courses, MSBTE news, exam tips, study guides, learning resources, academic success, educational support, MSBTE resources, exam strategies, technical knowledge, college studies, MSBTE updates, student community",
    name: "msbtewallah.in",
    type: "page"
}


export const NOTES_PAGE = {
    title: "Msbte Wallah - (Notes)",
    description: "Explore a comprehensive collection of subject-wise notes for all branches offered by MSBTE. Elevate your learning with meticulously crafted study materials tailored to empower MSBTE students across diverse disciplines. Access clear, concise, and insightful notes to excel in your technical education journey.",
    keywords: "MSBTE notes, subject-wise notes, branch-specific notes, technical education, study materials, MSBTE study resources, MSBTE study guides, engineering notes, diploma notes, exam preparation, academic success, learning materials, MSBTE syllabus, comprehensive notes, educational support",
    name: "msbtewallah.in",
    type: "page"
}


export const SYLLABUS_PAGE = {
    title: "Msbte Wallah - (Syllabus)",
    description: "Explore comprehensive subject-wise syllabus for all branches of Maharashtra State Board of Technical Education (MSBTE) on our platform. We offer a centralized resource for students, providing detailed curriculum information to aid in academic success and exam preparation. Access the latest syllabus updates and ensure a thorough understanding of your course content.",
    keywords: "MSBTE syllabus, Maharashtra State Board of Technical Education curriculum, subject-wise syllabus, diploma course syllabus, engineering syllabus, MSBTE exam preparation, technical education, MSBTE updates, academic curriculum, MSBTE branches, syllabus details, course content, MSBTE latest syllabus, diploma studies, study materials, exam resources.",
    name: "msbtewallah.in",
    type: "page"
}


export const SOLVED_MANUAL_PAGE = {
    title: "Msbte Wallah - (Solved Manual)",
    description: "Unlock success with our Solved Manuals! Your one-stop solution for comprehensive and subject-wise MSBTE Solved Manuals across all branches. Ace your exams with confidence, utilizing our expertly crafted resources. Elevate your learning experience and conquer every subject effortlessly.",
    keywords: "MSBTE Solved Manuals, subject-wise solutions, all branches, exam preparation, study guides, academic success, technical education, engineering studies, diploma courses, MSBTE exam resources, solved problems, question bank, exam confidence, expert solutions, learning support, college studies.",
    name: "msbtewallah.in",
    type: "page"
}


export const PREVIOS_YEAR_PAGE = {
    title: "Msbte Wallah - (Micro Projects)",
    description: "Explore a comprehensive collection of subject-wise Micro Projects for MSBTE across all branches. Dive into valuable insights and boost your exam preparation with our extensive archive of past question papers. Elevate your study experience with in-depth analysis and strategic learning resources for academic success.",
    keywords: "MSBTE Previous Year Papers, subject-wise Micro Projects, exam preparation, MSBTE all branches, past Micro Projects, academic success, strategic learning resources, exam insights, study materials, MSBTE exam practice, question paper archive, MSBTE exam tips, boost exam performance, educational resources, MSBTE exam strategies, succeed in exams",
    name: "msbtewallah.in",
    type: "page"
}

export const PREVIOS_YEAR_MODEL_ANSWER_PAGE = {
    title: "Msbte Wallah - (Previous Year Model Answer)",
    description: "Explore a comprehensive collection of subject-wise Previous Year Model Answers for all branches on our platform. Elevate your exam preparation with detailed solutions, insights, and guidance. Your one-stop destination for unlocking the secrets to academic success.",
    keywords: "Previous Year Model Answer, subject-wise solutions, exam preparation, academic success, model answer key, exam insights, study resources, all branches, exam guidance, past year papers, exam strategy, academic support, subject-specific solutions, exam success tips, exam preparation materials, detailed solutions.",
    name: "msbtewallah.in",
    type: "page"
}


export const VIDEO_LECTURE_PAGE = {
    title: "Msbte Wallah - (Video Lecture)",
    description: "Explore a comprehensive collection of subject-wise video lectures on our platform. Unlock a world of learning with video content covering all branches. Elevate your understanding of various subjects with our curated library of insightful and engaging video lectures.",
    keywords: "video lectures, subject-wise, all branches, educational videos, online learning, study resources, academic support, e-learning, educational content, learning platform, video tutorials, subject-specific videos, knowledge enhancement, online education, study aids",
    name: "msbtewallah.in",
    type: "page"
}


export const DASHBOARD_PAGE = {
    title: "Msbte Wallah - (Dashboard)",
    description: "",
    keywords: "",
    name: "msbtewallah.in",
    type: "page"
}

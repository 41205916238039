import videoChat from "../../assets/icons/videoChat.png"
import checklist from "../../assets/icons/checklist.png"
import notesImg from '../../assets/icons/notes1.png'
import manual from '../../assets/icons/manual.png'
import modelPaper from '../../assets/icons/answer.png'
import question from '../../assets/icons/question.png'

let option = [
    {
        title: "syllabus",
        type: "syllabus",
        description: "subject wise syllabus",
        img: checklist
    },
    {
        title: "notes",
        type: "notes",
        description: "subject wise Notes",
        img: notesImg
    },
    {
        title: "previous year Model Answers",
        type: "previous year Model Answer",
        description: "subject wise Model Answers",
        img: modelPaper
    },
    {
        title: "video lectures",
        type: "video lecture",
        description: "subject wise video lecture",
        img: videoChat
    },
    {
        title: "solved Manuals",
        type: "solved Manual",
        description: "subject wise solved Manual",
        img: manual
    },
    {
        title: "Micro Projects",
        type: "micro project",
        description: "subject wise micro projects",
        img: question
    },
]


const reducer = (state = option, action) => {
    switch (action.type) {
        case 'setStudyOption':
            return action.payload
        default:
            return state
    }
}

export default reducer
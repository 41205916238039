import Navbar from "../../../components/navbars/app_home_navbar/Navbar";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import SignUp from "./SignUp";
import Login from "./Login";
import DocumentViewer from "../pages/document_viewer/DocumentViewer";


const AuthRouter = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <div className='w-full h-full overflow-hidden bg-[#d4e0ff55] dark:bg-[#0f172a]'>
      <Routes>
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/document-viewer/:pdfurl" element={<DocumentViewer />} />
      </Routes>
    </div>
  )
}

export default AuthRouter
import React from 'react'
import useCurrentPage from '../../../../hooks/useCurrentPage'
import UploadsContainer from './UploadsContainer'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react"
import SubjectUpload from './SubjectUpload'
import { useState } from 'react'

const AddSubject = () => {


  let { setCurrentPageName } = useCurrentPage();
  const [activeTab, setActiveTab] = React.useState("myUploads");
  const [loadAgain, setLoadAgain] = React.useState(false)

  const data = [
    {
      label: "My Uploads",
      value: "myUploads",
      desc: <UploadsContainer loadAgain={loadAgain} setLoadAgain={setLoadAgain} />,
    },
    {
      label: "Upload New",
      value: "uploadNew",
      desc: <SubjectUpload loadAgain={loadAgain} setLoadAgain={setLoadAgain} />,
    }
  ];


  React.useEffect(() => {
    setCurrentPageName("Add Subject")
  }, [])
  return (
    <div className='cpBg dark:bg-[#0f1115] border-none py-5'>
      <Tabs value={activeTab}>
        <TabsHeader
          className="rounded-none border-b dark:bg-[#0f1115] border-blue-gray-50 bg-transparent felx justify-center items-center p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none dark:bg-[#0f1115]",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={activeTab === value ? "text-gray-900 dark:bg-[#0f1115] dark:text-white pb-5 " : "dark:bg-[#0f1115] dark:text-[#ffffff8d] pb-5"}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value} className='flex px-0 justify-center items-center'>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default AddSubject
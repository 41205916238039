import React from "react"
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Select,
    Option,
    Typography
} from "@material-tailwind/react"
import { useSelector } from "react-redux"
import { resourceType } from "../../../constants/College"
import useAuth from "../../../hooks/useAuth"
import { useState } from "react"
import axios from "axios"
import useAlert from "../../../hooks/useAlert"
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'

const BranchEdit = ({ open, handleOpen, loadAgain, setLoadAgain, editId, editName, setEditName, setEditCode, editCode, setEditScheme, editScheme }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert()

    const theme = useSelector(state => state.theme)
    const [tScheme, setTScheme] = useState("")

    const update = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branch/${editId}`,
                {
                    branchName: editName,
                    branchCode: editCode,
                    schemes: editScheme
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            showAlert("green", "Resource Updated successfully..!", 5000)
            setLoadAgain(!loadAgain)
        }
        catch (e) {
            console.log(e)
            if (e?.response?.data?.message) {
                console.log(e.response)
                showAlert("red", e.response.data.message, 5000)
            } else {
                showAlert("red", "something went wrong...!", 5000)
            }
        }
    }
    const addScheme = () => {
        setEditScheme([...new Set([...editScheme, tScheme])])
    }

    const clearAllScheme = () => {
        setEditScheme([])
    }



    return (
        <>
            <Dialog open={open} size="xs" handler={() => handleOpen("", "", [], "")} className="px-3 py-3 theme">
                <div className="flex items-center justify-between ">
                    <DialogHeader className="flex flex-col items-start text-theme">
                        <Typography className="mb-1 text-theme" variant="h4">
                            Edit
                        </Typography>
                    </DialogHeader>
                </div>
                <DialogBody className="overflow-y-auto h-[30rem]">
                    <div className="grid gap-6  mb-4">
                        <Input size="lg" type='text' label="Branch Name" value={editName} onChange={(e) => { setEditName(e.target.value) }} autoComplete='off' color={theme ? `gray` : 'white'} />
                    </div>
                    <div className="grid gap-6 mb-4">
                        <Input size="lg" type='text' label="Branch Code" value={editCode} onChange={(e) => { setEditCode(e.target.value) }} autoComplete='off' color={theme ? `gray` : 'white'} />
                    </div>
                    <div className="w-full flex gap-5 justify-center items-center flex-wrap mb-4">
                        <Input size="lg" type='text' label="Schemes" onChange={(e) => { setTScheme(e.target.value) }} autoComplete='off' color={theme ? `gray` : 'white'} />
                        <Button color="blue" onClick={addScheme}><AddIcon /></Button>
                        <Button color="blue" onClick={clearAllScheme}><ClearIcon /></Button>
                    </div>
                    <div className='w-full mb-5'>
                        {editScheme.map((val, index) => {
                            return (<p className="text-center dark:text-white" key={index}>{index + 1} : {val}</p>)
                        })}
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="text" className="text-theme" onClick={() => handleOpen("", "", [], "")}>
                        cancel
                    </Button>
                    <Button variant="gradient" color={theme ? `gray` : 'blue'} onClick={update} >
                        update
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default BranchEdit
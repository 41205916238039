import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
} from "@material-tailwind/react";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth'

const SettingsCard = ({ data, id, cardHeight, route, deleteFromList }) => {

    const { getUserInfo } = useAuth()
    const deleteCard = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}${route}${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            deleteFromList(id)
        }
        catch (e) {

        }
    }


    return (
        <Card className={`w-[18rem] h-[${cardHeight}] overflow-hidden dark:bg-[#0f172a] bg-[#f1f2f3]`}>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none"
            >
            </CardHeader>
            <CardBody className={`h-full`}>
                {
                    data
                }
            </CardBody>
            <CardFooter className="flex items-center justify-end">
                <div className='flex gap-3 items-center cursor-pointer'>
                    <DeleteIcon className='dark:text-white' onClick={() => { deleteCard(id) }} />
                </div>
            </CardFooter>
        </Card >
    )
}

export default SettingsCard
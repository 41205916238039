import React, { useState } from 'react'
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
} from "@material-tailwind/react"
import moment from 'moment'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ShareIcon from '@mui/icons-material/Share';


const ResourceCardWithoutDelete = ({ id, resourceName, subject, branch, semester, scheme, createdAt, userEmail, profilePic, type, path, likes, views, color, fav }) => {


    const { getUserInfo } = useAuth()
    const navigate = useNavigate()


    const [likesState, setLikesState] = useState(likes.length)
    const [userLikeTrue, setUserLikeTrue] = useState(getUserInfo()?.user?.id ? likes.includes(getUserInfo()?.user?.id) : null)
    const [viewsState, setViewState] = useState(views)
    const [favourite, setFavourite] = useState(fav)

    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }

    const addOrRemoveLike = async () => {
        if (getUserInfo() === null) {
            navigate("/auth/login")
        }
        else {
            try {
                const result = await axios.get(
                    `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource-like/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserInfo().token}`
                        }
                    }
                )
                setLikesState(result.data.likes.length)
                setUserLikeTrue(getUserInfo()?.user?.id ? result.data.likes.includes(getUserInfo()?.user?.id) : null)
            }
            catch (e) {

            }
        }
    }


    const addView = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource-view/${id}`
            )
            setViewState(result.data.views)
            console.log(path)
            if (type == "videoLecture") {
                window.open(path)
            }
            else {
                navigate(path)
            }

        }
        catch (e) {

        }
    }


    const save = async () => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/saved/`,
                {
                    resourceId: id
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            setFavourite(result.data.status)
        }
        catch (e) {

        }
    }

    const handleShare = async (text, url) => {
        if (navigator.share) {
            try {
                const res = await getShortUrl(url)
                const shortUrl = process.env.REACT_APP_MSBTE_WALLAH_BACKEND + "/" + res

                console.log(shortUrl)
                await navigator.share({
                    title: 'Share Study Material',
                    text: text + `\n ` + shortUrl
                });
                console.log('Successfully shared!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.warn('Web Share API not supported in this browser.');
            // Handle fallback or provide alternative sharing options
        }
    };

    const getShortUrl = async (url) => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/`,
                {
                    originalUrl: url
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            return result.data.shortUrl
        }
        catch (e) {

        }
    }


    function formatNumber(number) {
        if (number >= 1e9) {
            return (number / 1e9).toFixed(1) + 'B';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + 'M';
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }


    return (
        <Card className={`w-[19rem] xl:w-[20rem] h-[22rem] overflow-hidden ${color} border-b-[#3063F1] `}>
            <CardBody className='h-[17rem] cursor-pointer' onClick={addView}>
                <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize'>
                    {resourceName.length > 40 ? resourceName.slice(0, 40) + "........" : resourceName} ({type.replace(/([a-z])([A-Z])/g, '$1 $2')})
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    subject : {subject.length > 20 ? subject.slice(0, 20) + "........" : subject}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    branch : {branch}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    semester : {semester}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    scheme : {scheme}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    Uploaded At : {formatDate(createdAt)}
                </Typography>
            </CardBody>
            <CardFooter className="flex items-center justify-between">
                <div className="flex items-center -space-x-3 cursor-pointer">
                    <Tooltip content={userEmail}>
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="natali craig"
                            src={profilePic}
                            referrerPolicy="no-referrer"
                            className="border-2 border-white hover:z-10"
                            loading="lazy"
                        />
                    </Tooltip>
                </div>


                <div className='flex gap-5'>

                    <div className='flex gap-3 items-center cursor-pointer dark:text-white' onClick={save}>
                        {
                            favourite === undefined ? "" :
                                favourite == true ? <BookmarkIcon className='dark:text-white' /> : <BookmarkBorderIcon className='dark:text-white' />
                        }
                    </div>

                    <div className='flex gap-3 items-center cursor-pointer dark:text-white'
                        onClick={addOrRemoveLike}
                    >
                        {
                            userLikeTrue !== null ?
                                userLikeTrue === true ?
                                    <FavoriteIcon className='dark:text-white' /> :
                                    <FavoriteBorderIcon className='dark:text-white' />
                                : <FavoriteBorderIcon className='dark:text-white' />
                        }

                        {formatNumber(likesState)}
                    </div>

                    <div className='flex gap-3 items-center cursor-pointer dark:text-white' onClick={() => {
                        handleShare(`\n ${resourceName} ${type} : \n\n Sujbect : ${subject} \n Branch : ${branch} \n Semester : ${semester} \n Scheme : ${scheme} \n`, (process.env.REACT_APP_DOMAIN + path))
                    }}>
                        <ShareIcon className='dark:text-white' />
                    </div>
                    <div className='flex gap-3 items-center cursor-pointer dark:text-white' onClick={addView}>
                        {
                            <RemoveRedEyeIcon className='dark:text-white' />
                        }

                        {formatNumber(viewsState)}
                    </div>
                </div>


            </CardFooter>
        </Card>
    )
}

export default ResourceCardWithoutDelete
import FeedbackCard from "../../../components/cards/feedbackCard/FeedbackCard";
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../../assets/styles/home/main.css'
import { useSelector } from "react-redux";


const Feedback = () => {
  const feedback = useSelector(state => state.feedbacks.data)
  return (
    <div className="my-24" >
      <div className="flex flex-col justify-between gap-y-7 items-center mb-10" data-aos="zoom-in">
        <p className="text-[1.5rem] lg:text-2xl font-bold leading-3 text-[#3063F1] font-BalooBhaina2 ">

        </p>
        <h1 className="text-[1.8rem] text-center px-2 font-BalooBhaina2 font-bold lg:text-[2.6rem] dark:text-white">
          Recent Reviews
        </h1>
      </div>


      <div className="overflow-hidden" data-aos="fade-up">
        <div className="w-full md:w-[100%] px-5 py-10 sm:px-8 md:px-10 lg:px-5">
          <Swiper
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1536: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            className="mySwiper"
          >
            {
              feedback.map((value, index) => {
                return <SwiperSlide className={`rounded-2xl mb-5 dark:bg-[#0f1115]`} key={index} ><FeedbackCard key={index} name={value.name} title={value.title} description={value.description} stars={value.stars} img={value.img} /></SwiperSlide>
              })
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Feedback
import { combineReducers } from "redux";
import Feedbackreducer from "./Feedbackreducer";
import ThemeReducer from './ThemeReducer'
import StudyReducer from './StudyReducer'
import StudyStateReducer from "./StudyStateReducer";
import UserReducer from "./UserReducer";
import AlertReducer from "./AlertReducer";
import ProfileReducer from "./ProfileReducer";
import CurrentPageReducer from "./CurrentPageReducer";
import NotificationReducer from "./NotificationReducer"
import PwaInstallActionReducer from "./PwaInstallActionReducer"
import ViewsReducer from "./ViewsReducer"
import PrivacyReducer from "./PrivacyReducer"


const reducers = combineReducers({
    feedbacks: Feedbackreducer,
    theme: ThemeReducer,
    study: StudyReducer,
    studyState: StudyStateReducer,
    user: UserReducer,
    alert: AlertReducer,
    profile: ProfileReducer,
    currentPage: CurrentPageReducer,
    Notification: NotificationReducer,
    pwaInstall: PwaInstallActionReducer,
    views: ViewsReducer,
    privacy: PrivacyReducer
})

export default reducers
import {
  Navbar,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { BellIcon, Cog6ToothIcon, Bars3BottomLeftIcon, HomeIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useCurrentPage from '../../../hooks/useCurrentPage'

const DashboardNavbar = ({ handleSidebar }) => {
  let { currentPage } = useCurrentPage();


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [pageYOffset, setPageYOffset] = useState(0)

  useEffect(() => {

    let lastScrollPosition = window.pageYOffset;


    const handleScroll = () => {
      setIsMenuOpen(false)

      setPageYOffset(window.pageYOffset)

      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > 100) {

        if (currentScrollPosition > lastScrollPosition) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
      }
      else {
        setScrollDirection(null);
      }
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener('scroll', handleScroll);




    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <Navbar
      className={`w-[88%] mx-5 md:mx-0 xl:right-6 z-40 sm:w-[93%] md:w-[50%] 2xl:w-[76%] lg:w-[67%] 2x:w-full   ${pageYOffset < 200 ? "fixed bg-[#ffffff]" : "bg-[#ffffff] dark:bg-[#17233f]"} ${scrollDirection === "down" ? "hidden fixed" : scrollDirection === "up" ? "flex fixed" : "flex "} justify-between bg-[#ffffff] dark:bg-[#0f1115] border-none`}
    >
      <Link>
        <Typography
          variant="h6"
          color="blue-gray"
          className="mr-4 ml-2 cursor-pointer py-1.5 capitalize dark:text-white"
        >
          {currentPage}
        </Typography>
      </Link>
      <div className="flex flex-wrap items-center justify-between gap-y-4 text-black">
        <div className="ml-auto flex gap-1 md:mr-4 ">
          <IconButton variant="text" color="blue-gray" onClick={handleSidebar} className="md:hidden">
            <Bars3BottomLeftIcon className="h-5 w-5 dark:text-white" />
          </IconButton>
          <Link to="/">
            <IconButton variant="text" color="blue-gray" className="dark:text-white">
              <HomeIcon className="h-4 w-4" />
            </IconButton>
          </Link>
        </div>
      </div>
    </Navbar>
  );
}

export default DashboardNavbar
import React from "react";
import { Alert } from "@material-tailwind/react";
import { useSelector, useDispatch } from "react-redux";
import { AlertActionCreators } from "../../state/index";
import { bindActionCreators } from "redux"

function AlertPopUp() {

  
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(AlertActionCreators, dispatch)
  const alert = useSelector(state => state.alert)
  
  return (
    <>
      <Alert
        open={alert.open}
        onClose={() => setAlert({
          color: "red",
          msg: "",
          open: false
        })}
        color={alert.color}
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
        className="fixed bottom-20 left-5 w-[90%] capitalize z-50 sm:bottom-5 sm:right-5 sm:w-[30%]"
      >
        {alert.msg}
      </Alert>
    </>
  );
}

export default AlertPopUp;
import React from "react";
import Header from "../../../components/headers/home_header/Header";
import StatsCounter from "../../../components/stats_counter_section/home_stats_counter/StatsCounter";

const HeaderSection = ({content}) => {
  return (
    <>
      <Header content={content} />
      <StatsCounter />
    </>
  );
};

export default HeaderSection;

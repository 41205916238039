import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SettingsCard from '../../../../components/cards/resourceCard/SettingsCard';
import { Typography } from '@material-tailwind/react';
import Lottie from "react-lottie-player";
import loading from "../../../../assets/lottie_files/notFoundAnimation.json";
import EditIcon from '@mui/icons-material/Edit'
import BranchEdit from '../../../../components/modal/branch_edit/BranchEdit';

const UploadsContainer = ({ cards, deleteFromList, loadAgain, setLoadAgain }) => {

    const [open, setOpen] = useState(false)
    const [editName, setEditName] = useState("")
    const [editCode, setEditCode] = useState("")
    const [editScheme, setEditScheme] = useState([])
    const [editId, setEditId] = useState("")

    const handleOpen = (name, code, scheme, id) => {
        setOpen(!open)
        setEditName(name)
        setEditCode(code)
        setEditScheme(scheme)
        setEditId(id)
    }


    return (
        <div className='flex h-full p-5 flex-wrap items-center gap-10 overflow-auto justify-center'>
            <BranchEdit open={open} handleOpen={handleOpen} loadAgain={loadAgain} setLoadAgain={setLoadAgain} editId={editId} editName={editName} setEditName={setEditName} setEditCode={setEditCode} editCode={editCode} setEditScheme={setEditScheme} editScheme={editScheme} />
            <Typography className=" flex justify-center capitalize blue-gray dark:text-[#e4e4e4] font-ligh w-full">Total : {cards.length}</Typography>

            {
                (cards.length === 0) ? <Lottie
                    loop
                    animationData={loading}
                    play
                    className="w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
                /> :
                    cards.map((data, key) => {
                        let cardBody = [
                            (
                                <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize flex gap-3 justify-between '>
                                    {data.branchName}
                                    <div className='flex gap-3 items-center  cursor-pointer  '>
                                        <EditIcon className='dark:text-white' onClick={() => { handleOpen(data.branchName, data.branchCode, data.schemes, data._id) }} />
                                    </div>
                                </Typography>
                            ),
                            (
                                <Typography variant="h6" className="mt-3 font-normal capitalize text-sm dark:text-white">
                                    branch code : <span className="uppercase" >{data.branchCode}</span>
                                </Typography>
                            ),
                            (
                                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                                    semesters : {data.semesters.join(", ")}
                                </Typography>
                            ),
                            (
                                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                                    schemes : {data.schemes.join(", ")}
                                </Typography>
                            )
                        ]
                        return (<SettingsCard key={key} data={cardBody} id={data._id} route={"/branches/branch/"} cardHeight={"20rem"} deleteFromList={deleteFromList} />)
                    })
            }
        </div>
    )
}

export default UploadsContainer
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SettingsCard from '../../../../components/cards/resourceCard/SettingsCard'
import { Typography } from '@material-tailwind/react'
import Lottie from "react-lottie-player"
import loading from "../../../../assets/lottie_files/notFoundAnimation.json"
import useAlert from '../../../../hooks/useAlert'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from "@material-tailwind/react"
import EditIcon from '@mui/icons-material/Edit';
import SubjectEdit from '../../../../components/modal/subject_edit/SubjectEdit'

const UploadsContainer = ({ loadAgain, setLoadAgain }) => {

    const { showAlert } = useAlert();

    const [branchList, setBranchList] = useState([])
    const [subjectList, setSubjectList] = useState([])
    const [schemeList, setSchemeList] = useState([])
    const [semesterList, setSemesterList] = useState([])
    const [branch, setBranch] = useState("All")
    const [scheme, setScheme] = useState("All")
    const [semester, setSemester] = useState("All")
    const [active, setActive] = useState(1)
    const [limit, setLimit] = useState(3)
    const [totalRecords, setTotalRecords] = useState(0)
    const [open, setOpen] = useState(false)

    const [editName, setEditName] = useState("")
    const [editCode, setEditCode] = useState("")
    const [editBranch, setEditBranch] = useState([])
    const [editScheme, setEditScheme] = useState([])
    const [editId, setEditId] = useState("")


    const handleOpen = (name, code, scheme, branch, id) => {
        setOpen(!open)
        setEditName(name)
        setEditCode(code)
        setEditScheme(scheme)
        setEditBranch(branch.map((val) => val._id))
        setEditId(id)
    }


    const fetchBranches = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branches`
            )
            setBranchList(result.data)
        }
        catch (e) {
            showAlert("red", "something went wrong", 2000)
        }
    }


    const fetchSubject = async () => {
        try {

            const data = {
                branch: branch,
                scheme: scheme,
                semester: semester,
                limit: limit,
                page: 1
            }

            if (data.branch == "All") {
                delete data.branch
            }

            if (data.scheme == "All") {
                delete data.scheme
            }

            if (data.semester == "All") {
                delete data.semester
            }

            if (data.approval == "All") {
                delete data.approval
            }

            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/subjects`,
                {
                    params: data
                }
            );
            setSubjectList(result.data.data);
            setTotalRecords(result.data.totalRecords)
            console.log(result)
        } catch (e) {
            showAlert("red", "something went wrong", 2000)
        }
    };


    const fetchMoreSubject = async () => {
        try {
            setActive(active + 1)
            const data = {
                branch: branch,
                scheme: scheme,
                semester: semester,
                limit: limit,
                page: active + 1
            }

            if (data.branch == "All") {
                delete data.branch
            }

            if (data.scheme == "All") {
                delete data.scheme
            }

            if (data.semester == "All") {
                delete data.semester
            }

            if (data.approval == "All") {
                delete data.approval
            }

            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/subjects`,
                {
                    params: data
                }
            );
            setSubjectList(subjectList.concat(result.data.data))
            console.log(result)
        } catch (e) {
        }
    };

    const setSubjects = (e) => {
        setActive(1)
        let id = e.target.value
        try {
            if (e.target.value != "") {

                let obj = branchList.find((val) => val._id === id)
                setSchemeList(obj.schemes)
                setBranch(id)
                setSemesterList(obj.semesters)
            }
        } catch {
            setBranch(id)
        }
    }

    const setSchemes = (e) => {
        setActive(1)
        if (e.target.value != "") {
            setScheme(e.target.value)
        }

    }

    const setSemesters = (e) => {
        setActive(1)
        if (e.target.value != "") {
            setSemester(e.target.value)
        }

    }

    const deleteFromList = (id) => {
        setSubjectList(subjectList.filter((val) => val._id !== id));
        setTotalRecords(subjectList.filter((val) => val._id !== id).length)
    }

    useEffect(() => {
        setActive(1)
        fetchSubject()
        fetchBranches()
    }, [loadAgain])

    useEffect(() => {
        if (branch != "" && scheme != "" && semester != "") {
            fetchSubject()
        }
    }, [branch, scheme, semester])


    return (
        <div className='p-5 flex flex-col w-full h-full'>
            <SubjectEdit open={open} handleOpen={handleOpen} loadAgain={loadAgain} setLoadAgain={setLoadAgain} editId={editId} editName={editName} setEditName={setEditName} editCode={editCode} setEditCode={setEditCode} editBranch={editBranch} setEditBranch={setEditBranch} editScheme={editScheme} setEditScheme={setEditScheme} />

            <div className='w-full flex justify-end mt-0 h-full'>
                <div className="w-full h-full flex flex-wrap gap-5 md:gap-10 my-5 justify-center">
                    <select onChange={setSubjects} className={`w-24 md:w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Branch</option>
                        {
                            branchList.map((val) => {
                                return <option value={val._id}>{val.branchName}</option>
                            })
                        }
                    </select>

                    <select onChange={setSchemes} className={`w-24 md:w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Scheme</option>
                        {
                            schemeList.map((val) => {
                                return <option value={val}>{val}</option>
                            })
                        }
                    </select>

                    <select onChange={setSemesters} className={`w-28 md:w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        <option value="All">Semester</option>
                        {
                            semesterList.map((val) => {
                                return <option value={val}>{val}</option>
                            })
                        }
                    </select>


                </div>
            </div>
            <Typography className=" flex justify-center mt-5 capitalize blue-gray dark:text-[#e4e4e4] font-ligh mb-12 ">Total : {totalRecords}</Typography>

            <InfiniteScroll
                dataLength={subjectList.length}
                next={() => {
                    fetchMoreSubject()
                }}
                hasMore={subjectList.length !== totalRecords}
                loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-5'>
                    <Spinner color="blue" className='w-16 h-16' />
                </div>}
            >
                <div className='w-full h-full flex flex-wrap gap-14 justify-center'>
                    {
                        (subjectList.length === 0) ? <Lottie
                            loop
                            animationData={loading}
                            play
                            className="w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
                        /> :
                            subjectList.map((data) => {
                                let cardBody = [
                                    (
                                        <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize flex justify-between'>
                                            {data.name}
                                            <div className='flex gap-3 items-center  cursor-pointer'>
                                                <EditIcon className='dark:text-white' onClick={() => { handleOpen(data.name, data.code, data.scheme, data.branch, data._id) }} />
                                            </div>
                                        </Typography>
                                    ),
                                    (
                                        <Typography variant="h6" className="mt-3 font-normal text-sm uppercase dark:text-white">
                                            {data.code}
                                        </Typography>
                                    ),
                                    (
                                        <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                                            branch : {data?.branch.map((val) => (<p> {val.branchName} </p>))}
                                        </Typography>
                                    ),
                                    (
                                        <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                                            semester : {data?.semester}
                                        </Typography>
                                    ),
                                    (
                                        <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                                            scheme : {data?.scheme?.join(", ")}
                                        </Typography>
                                    )
                                ]
                                return (<SettingsCard data={cardBody} id={data._id} route={"/branches/subject/"} cardHeight={"10rem"} deleteFromList={deleteFromList} />)
                            })
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default UploadsContainer
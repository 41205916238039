import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"
import useAuth from '../../../../hooks/useAuth'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import axios from 'axios'
import { Spinner } from '@material-tailwind/react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '@mui/icons-material/Home'


const DocumentViewer = () => {

    let { pdfurl } = useParams()
    let { checkSession } = useAuth()
    const { getUserInfo } = useAuth()
    const navigate = useNavigate();


    const theme = useSelector(state => state.theme)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [docs, setDocs] = useState("...")
    const [isLoading, setIsLoading] = useState(true);
    const [resource, setResource] = useState(null);


    const resources = useCallback(async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource/?id=${pdfurl}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )
            setResource(res?.data)
            setDocs(res?.data?.path)
            setIsLoading(false)




        } catch (e) {
            console.log(e)
        }

    }, []);

    let transform

    transform = (slot) => ({
        ...slot,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>
    });



    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );
    let defaultLayoutPluginInstance

    if (screenWidth < 700) {
        defaultLayoutPluginInstance = defaultLayoutPlugin({
            renderToolbar,
            sidebarTabs: (defaultTabs) => [],
        });
    }
    else {
        defaultLayoutPluginInstance = defaultLayoutPlugin({
            renderToolbar,
        });
    }

    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    useEffect(() => {
        if (getUserInfo() == null) {
            checkSession()
        }
        else {
            resources()
        }

        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        const disableRightClick = (event) => {
            event.preventDefault();
        };

        const disableShortcuts = (event) => {
            const functionKeys = [112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123];
            if (functionKeys.includes(event.keyCode)) {
                event.preventDefault();
            }
        };

        window.addEventListener('contextmenu', disableRightClick);
        window.addEventListener('keydown', disableShortcuts);




        return () => {
            window.removeEventListener('contextmenu', disableRightClick);
            window.removeEventListener('keydown', disableShortcuts);
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    return (
        <div className='bg-[#eeeeee] dark:bg-[#292929] h-screen w-screen overflow-y-scroll hide-scrollbar'>
            <nav className={`w-full h-8 bg-[#eeeeee] dark:bg-[#292929] px-2 flex flex-row justify-between items-center`}>
                <button onClick={() => navigate(-1)} className='p-1 text-gray-700 dark:text-gray-100'><ArrowBackIcon /></button>
                <p className='text-gray-700 dark:text-gray-100 capitalize p-1 text-sm md:text-[1rem]'>{resource?.resourceName.length > 9 && screenWidth < 500 ? resource?.resourceName.substring(0, 30) + "......" : resource?.resourceName}</p>
                <Link to="/" className='p-1 text-gray-700 dark:text-gray-100'><HomeIcon /></Link>
            </nav>
            {
                isLoading ? <div className='w-full bg-[#eeeeee] dark:bg-[#1a1a1a]  h-screen overflow-hidden flex justify-center items-center mb-5'>
                    <Spinner className="h-20 w-20" color='blue-gray' />
                </div> :
                    (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

                            <Viewer

                                theme={!theme ? "dark" : "light"}
                                fileUrl={docs}
                                plugins={[defaultLayoutPluginInstance]}
                                defaultScale={SpecialZoomLevel.PageFit}
                                renderLoader={(percentages) => <div style={{ width: '240px' }}></div>}
                            />
                        </Worker>
                    )
            }

        </div >
    )
}
export default DocumentViewer
import React, { useEffect, useState } from 'react'
import { Input } from "@material-tailwind/react";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { Button } from '@material-tailwind/react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import useAuth from '../../../../hooks/useAuth';
import axios from 'axios';
import useAlert from "../../../../hooks/useAlert";

const BranchUpload = ({ fetchBranches }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert()

    const {
        handleSubmit, watch, setValue, register
    } = useForm();

    const theme = useSelector(state => state.theme)

    const branchName = watch('branchName')
    const branchCode = watch('branchCode')
    const semesters = watch('semesters')
    const scheme = watch('scheme')

    const [schemes, setSchemes] = useState([])

    const addScheme = () => {
        if (scheme != "") {
            setSchemes([...schemes, scheme.trim()])
            setValue("scheme", "")
        }

    }

    const ClearAllScheme = () => {
        setSchemes([])
    }


    const postBranches = async () => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branch`,
                {
                    branchName,
                    branchCode,
                    semesters,
                    schemes: schemes
                }
                ,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                }
            )

            console.log(result)
            showAlert("green", "Branch Added Successfully...!", 5000)
            fetchBranches()
        }
        catch (e) {
            console.log(e)
            showAlert("red", "something went wrong...!", 5000)
        }
    }


    return (
        <form className='w-full gap-5 items-center h-full flex flex-col px-5 xl:px-20 pt-10' onSubmit={handleSubmit(postBranches)}>
            <Input size="lg" type='text' autoComplete='off' label="Branch Name" color={theme ? `gray` : 'white'} {...register('branchName', { required: true })} />
            <Input size="lg" type='text' autoComplete='off' label="Branch Code" color={theme ? `gray` : 'white'} {...register('branchCode', { required: true })} />
            <div className='w-full flex gap-5 flex-wrap justify-center'>
                <Input size="lg" type='text' autoComplete='off' label="Scheme" color={theme ? `gray` : 'white'}  {...register('scheme')} />
                <Button color="blue" onClick={addScheme}><AddIcon /></Button>
                <Button color="blue" onClick={ClearAllScheme}><ClearIcon /></Button>
            </div>
            <div className='w-full dark:text-white'>
                {
                    schemes.map((val, index) => {

                        return (<p className="text-center dark:text-white" key={index}>{index + 1} : {val}</p>)

                    })
                }
            </div>
            <Input size="lg" type='text' autoComplete='off' label="Semesters" color={theme ? `gray` : 'white'} {...register('semesters', { required: true })} />
            <Button color="blue" type='submit'>Add</Button>
        </form>
    )
}

export default BranchUpload
import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import Seo from '../../../../components/seo/Seo';
import { ABOUT_PAGE as PAGE } from '../../../../constants/Seo';
import Footer from '../../home/Footer';

const About = () => {
  return (
    <div className='w-full min-h-screen max-h-full flex justify-center bg-white flex-col dark:bg-[#0f172a] bg-fixed'>
      <Seo
        title={PAGE.title}
        description={PAGE.description}
        keywords={PAGE.keywords}
        name={PAGE.name}
        type={PAGE.type} />
      <div className='w-full flex justify-center items-center pt-15 sm:pt-20 h-60 sm:h-80 md:pb-12 bg-[#f1f2f3] dark:bg-[#0f172a] '>
        <Typography variant="h1" className='dark:text-white pt-5 text-4xl'>
          About Us
        </Typography>
      </div>
      <div className='w-full min-h-screen flex rounded-t-3xl dark:bg-[#0f1115] px-8 py-5 xl:py-16 xl:px-32 flex-col  dark:text-white'>
        <Typography variant="small">
          <Typography variant="h3" className='dark:text-white pt-5 md:pt-5 text-2xl'>Welcome to MSBTE Wallah - Your Ultimate Learning Companion! </Typography>
          <br />
          <br />
          <Typography variant="p" className='dark:text-white '>
            Greetings! I am Niraj Bava, a passionate learner and proud student of the Maharashtra State Board of Technical Education (MSBTE). As a fellow MSBTE enthusiast, I understand the challenges and demands of technical education, and it is my pleasure to introduce you to MSBTE Wallah, an educational initiative that aims to simplify your academic journey.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" className='dark:text-white '>
            Our Mission
          </Typography>

          <br />
          <br />
          <Typography variant="p" className='dark:text-white '>
            At MSBTE Wallah, our mission is to empower MSBTE students by providing a comprehensive platform that houses a wealth of study resources. We believe in the transformative power of education and its ability to open doors to a brighter future. Whether you're striving for excellence in exams, seeking additional study materials, or simply aiming to enhance your understanding of technical subjects, MSBTE Wallah is here to support you every step of the way.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" className='dark:text-white '>
            What We Offer
          </Typography>
          <br />
          <br />
          <Typography variant="p" className='dark:text-white '>
            1. Comprehensive Study Material: Access a vast repository of study materials, including detailed notes, subject-wise resources, and curated content to help you grasp complex concepts with ease.
            <br />
            <br />

            2. Previous Year Model Papers: Prepare confidently for your exams by practicing with a collection of previous year model papers. Understand the exam pattern and enhance your problem-solving skills.
            <br />
            <br />

            3. Video Lectures: Reinforce your learning experience with video lectures from experienced educators. Visualize and understand topics in-depth at your own pace.
            <br />
            <br />
            4. Syllabus Coverage: Stay on top of your curriculum with up-to-date and organized syllabus information. Plan your study sessions effectively with our syllabus coverage feature.
            <br />
            <br />
            5. Community Support: Connect with fellow MSBTE students through our community forums. Share knowledge, seek guidance, and foster a collaborative learning environment.
            <br />
            <br />
          </Typography>
          <Typography variant="h4" className='dark:text-white '>
            Why Choose MSBTE Wallah?
          </Typography>
          <br />
          <br />
          <Typography variant="p" className='dark:text-white '>
            By Students, For Students: MSBTE Wallah is not just an app; it's a product of my personal dedication to making learning accessible and enjoyable for my fellow MSBTE students.
            <br />
            Comprehensive and Convenient: We understand the diverse needs of MSBTE students, and our platform is designed to be a one-stop solution for all your educational requirements.
            <br />
            Continuous Improvement: We are committed to continuously updating and expanding our content to align with the dynamic nature of technical education.
            <br />
            <br />
            <Typography variant="h4" className='dark:text-white '>
              Join Us on the Learning Journey
            </Typography>
            <br />
            Embark on a fulfilling learning journey with MSBTE Wallah. Whether you're a fresher navigating through your first semester or a seasoned student aiming for excellence, MSBTE Wallah is here to support you.
            <br />
            <br />
            Thank you for choosing MSBTE Wallah – Where Learning Meets Innovation!
            <br />
            <br />
          </Typography>

          <Typography variant="p" className='dark:text-white '>
            Best regards,
            Niraj Bava
            <br />
            Founder & CTO, MSBTE Wallah
            <br />
            <Link to="mailto:msbtewallah@gmail.com" className='text-blue-600'>msbtewallah@gmail.com</Link>
          </Typography>
        </Typography>

      </div>
      <Footer />
    </div>

  )
}

export default About
import React from "react"
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Select,
    Option,
    Typography
} from "@material-tailwind/react"
import { useSelector } from "react-redux"
import { resourceType } from "../../../constants/College"
import useAuth from "../../../hooks/useAuth"
import { useState } from "react"
import axios from "axios"
import useAlert from "../../../hooks/useAlert"

const ResourcesEditModal = ({ open, handleOpen, editId, loadAgain, setLoadAgain, editName, editType, setEditName, setEditType }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert();

    const theme = useSelector(state => state.theme)
    const [typeList] = useState(resourceType.filter((item, index) => index != resourceType.length - 1))

    const update = async () => {
        try {
            await axios.patch(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource?id=${editId}`,
                {
                    type: editType,
                    resourceName: editName
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            showAlert("green", "Resource Updated successfully..!", 5000)
            setLoadAgain(!loadAgain)
        }
        catch (e) {
            if (e?.response?.data?.message) {
                console.log(e.response)
                showAlert("red", e.response.data.message, 5000)
            } else {
                showAlert("red", "something went wrong...!", 5000)
            }
        }
    }


    return (
        <>
            <Dialog open={open} size="xs" handler={() => handleOpen("", "", "")} className="px-3 py-3 theme">
                <div className="flex items-center justify-between ">
                    <DialogHeader className="flex flex-col items-start text-theme">
                        <Typography className="mb-1 text-theme" variant="h4">
                            Edit
                        </Typography>
                    </DialogHeader>
                </div>
                <DialogBody>
                    <div className="grid gap-6">
                        <Input size="lg" type='text' label="Resource Name" value={editName} onChange={(e) => setEditName(e.target.value)} autoComplete='off' color={theme ? `gray` : 'white'} />
                    </div>
                    <div className={`w-full my-5 ${editType == "videoLecture" ? "hidden" : ""}`}>
                        <Select label="Type" value={editType} style={{ color: theme ? "gray" : "white" }} onChange={(e) => { setEditType(e) }} className='capitalize' >
                            {typeList.map((value, key) => <Option key={key} className='capitalize' value={value}>{value.replace(/([a-z])([A-Z])/g, '$1 $2')}</Option>)}
                        </Select>
                    </div>

                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="text" className="text-theme" onClick={() => handleOpen("", "", "")}>
                        cancel
                    </Button>
                    <Button variant="gradient" color={theme ? `gray` : 'blue'} onClick={update} >
                        update
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default ResourcesEditModal
import React from "react";
import logo from "../../../assets/icons/logo.png";
import { Link } from "react-router-dom";
import linkedinLogo from '../../../assets/icons/linkedin.png';
import emailLogo from '../../../assets/icons/email.png';
import footerbg from '../../../assets/backgrounds/footer.svg'

const Footer = () => {
  return (
    <>
      <div>
        <img src={footerbg} className="w-full h-full" alt="wave" />
      </div>
      <footer className="w-full f-full bg-[#3063F1] flex flex-col pt-5 pb-5 text-white leading-7 font-BalooBhaina2 md:gap-y-12 md:pt-0 justify-center items-center">
        <div className="flex w-full flex-wrap items-center gap-y-4 py-10 pt-4 px-5 md:flex-row md:justify-center md:gap-x-5 md:px-28 md:pr-48 lg:gap-x-16 ">
          <div className="flex justify-center w-full flex-wrap items-center gap-y-10 md:justify-center md:px-20  md:w-auto md:gap-x-12 md:p-0 lg:gap-x-40">
            <div className="flex flex-col justify-center items-center text-center gap-y-2" data-aos="zoom-in">
              <Link
                className="w-24 md:w-28 h-24 md:h-28 cursor-pointer  bg-transparent shadow-none bg-center bg-no-repeat rounded-md"
                style={{
                  backgroundImage: `url(${logo})`,
                  backgroundSize: "100% 100%",
                }}
                to="/"
              ></Link>
              <h2 className="font-bold text-2xl md:text-[2.5rem]">Msbte Wallah</h2>
              <p className="text-[1.2rem]">The Ultimate Guide To Top Msbte.</p>
            </div>

            <div className="flex justify-center flex-col items-center text-base lg:text-xl align-middle text-center px-16 mb-5 sm:mb-0 h-full md:pt-7">
              <div className="flex justify-between text-[1.2rem] flex-col gap-3">
                <h2 className="font-bold text-2xl lg:text-2xl" data-aos="fade-down" data-aos-delay="1000">Services</h2>
                <Link data-aos="fade-down" to="/pages/about" data-aos-delay="600">About</Link>
                <Link data-aos="fade-down" to="/pages/term-of-use" data-aos-delay="600">Terms of use</Link>
                <Link data-aos="fade-down" to="/pages/privacy-policy" data-aos-delay="800">Privacy Policy</Link>
                <Link data-aos="fade-down" to="/pages/contact-us" data-aos-delay="800">Contact US</Link>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center text-center w-full flex-col gap-y-5 md:w-auto md:pt-7" data-aos="zoom-in">
            <h2 className="text-center  font-bold text-2xl lg:text-2xl">Get Into Touch</h2>
            <div className="flex gap-x-5">
              <Link to="https://www.linkedin.com/in/nirajbava/" target="_blank"><img loading="lazy" src={linkedinLogo} alt="linkedin" className="w-14 h-14 md:w-16 md:h-16 cursor-pointer" /></Link>
              <Link to="/pages/contact-us" target="_blank"><img loading="lazy" src={emailLogo} alt="linkedin" className="w-14 h-14 md:w-16 md:h-16 cursor-pointer" /></Link>
            </div>
          </div>
        </div>
        <div className="text-center px-[0.8rem] text-[1.2rem] font-medium flex flex-wrap h-full justify-center items-center lg:text-xl" data-aos-offset="20" data-aos="fade-up">
          Copyright © 2023 Msbte Wallah. <Link to="/pages/privacy-policy" className="ml-1"> All Rights
            Reserved.</Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;

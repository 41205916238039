import {
  UserCircleIcon,
  UserPlusIcon,
  AdjustmentsHorizontalIcon,
  UserMinusIcon,
  BookmarkIcon,
  ArrowDownTrayIcon
} from "@heroicons/react/24/outline";

const profileMenuItems = [
  {
    label: "Sign Up",
    link: "/auth/signUp",
    type: "",
    icon: UserPlusIcon,
  },
  {
    label: "Login In",
    link: "/auth/login",
    type: "",
    icon: UserCircleIcon,
  }
];

const reducer = (state = profileMenuItems, action) => {
  switch (action.type) {
    case 'setProfile':
      return profileMenuItems
    case 'updateProfileLogin':
      return [{
        label: "Dashboard",
        link: "/dashboard/uploads",
        type: "",
        icon: AdjustmentsHorizontalIcon,
      },
      {
        label: "Favourites",
        link: "/dashboard/favourites",
        type: "",
        icon: BookmarkIcon,
      },
      {
        label: "Logout",
        link: "",
        type: "logout",
        icon: UserMinusIcon,
      }
      ]
    default:
      return state
  }
}

export default reducer
import StatsCard from "./StatsCard";
import '../../../assets/styles/home/main.css'
import axios from "axios";
import { useEffect, useState } from "react";

const StatsCounter = () => {

  const [visitors, setVisitors] = useState(0)
  const [users, setUsers] = useState(0)
  const [installs, setInstalls] = useState(0)
  const [visitorsSign, setVisitorsSign] = useState("")
  const [usersSign, setUsersSign] = useState("")
  const [installsSign, setInstallsSign] = useState("")

  const formatNumber = (number) => {
    if (number >= 1e9) {
      return [(number / 1e9).toFixed(1), 'B'];
    } else if (number >= 1e6) {
      return [(number / 1e6).toFixed(1), 'M'];
    } else if (number >= 1e3) {
      return [(number / 1e3).toFixed(1), 'K'];
    } else {
      return [number.toString(), ""];
    }
  }

  let cards = [
    {
      value: visitors,
      suffix: visitorsSign + "+",
      text1: "Total",
      text2: "Visitors",
    },
    {
      value: users,
      suffix: usersSign + "+",
      text1: "Users",
      text2: "Community",
    },
    {
      value: installs,
      suffix: installsSign + "+",
      text1: "App",
      text2: "Installation",
    }
  ];

  const getCounts = async (id) => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/counters/`)

      let visi = formatNumber(res.data.visitorsCount)
      setVisitors(visi[0])
      setVisitorsSign(visi[1])

      let use = formatNumber(res.data.usersCount)
      setUsers(use[0])
      setUsersSign(use[1])

      let ins = formatNumber(res.data.installcount)
      setInstalls(ins[0])
      setInstallsSign(ins[1])

    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    getCounts()
  }, [])

  return (
    <div className="w-full h-full flex gap-0 justify-evenly py-12 sm:py-16 xl:py-16 sm:px-10 md:px-20 lg:px-44 items-center bg-[#3063F1] bg dark:bg-[#3063f1f4] ">
      {cards.map((value, index) => {
        return (<StatsCard key={index} no={index} text1={value.text1} text2={value.text2} value={value.value} suffix={value.suffix} />)
      })}
    </div>
  );
};

export default StatsCounter;

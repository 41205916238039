import React, { useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
} from "@material-tailwind/react";
import moment from 'moment';

const UsersCard = ({ id, fullName, email, role, profilePic, createdAt }) => {


    let [cardVisibility, setCardVisibility] = useState(false)

    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }


    return (
        <Card className={`w-full md:w-[20rem] h-[20rem] overflow-hidden dark:bg-[#0f172a] cursor-pointer bg-[#f1f2f3]  ${cardVisibility ? "hidden" : ""}`}>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none"
            >
            </CardHeader>
            <CardBody className='h-[14rem]'>
                <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize'>
                    id : {id}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    full name : {fullName}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    email : {email}
                </Typography>
                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    role : {role}
                </Typography>

            </CardBody>
            <CardFooter className="flex items-center justify-between">
                <div className="flex items-center -space-x-3">
                    <Tooltip content={email}>
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="natali craig"
                            src={profilePic}
                            referrerPolicy="no-referrer"
                            className="border-2 border-white hover:z-10"
                        />
                    </Tooltip>

                </div>
                <div className='flex gap-3 items-center dark:text-white'>
                    {formatDate(createdAt)}
                </div>

            </CardFooter>
        </Card>
    )
}

export default UsersCard
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavbarProfile from "./NavbarProfile";
import homeMenu from "../../../assets/icons/homeMenu.png";
import logo from "../../../assets/icons/logo.png";
import { Button } from "@material-tailwind/react";
import MobileSliderMenu from "./MobileSliderMenu";
import homeMenuDark from './../../../assets/icons/homeMenuDark.png'
import { useSelector } from "react-redux";


const Navbar = () => {

  const theme = useSelector(state => state.theme)
  const studyState = useSelector(state => state.studyState)
  // mobile side bar menu stateY
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const openDrawer = () => {
    setOpen(true);
    document.body.style.overflow = "hidden";
  }
  const closeDrawer = () => {
    document.body.style.overflow = "auto";
    setOpen(false)
  };

  let title = <p><span className="text-[#3063F1]">Msbte</span> Wallah</p>;


  const getStudyLink = () => {
    return studyState.branch != null && studyState.scheme != null && studyState.semester != null
      ? `/pages/study/${studyState.branch}/${studyState.scheme}/${studyState.semester}`
      : "/pages/scheme";
  }


  let [menuList, setMenuList] = useState([
    {
      menuName: "Study",
      menuLink: getStudyLink(),
    },
    {
      menuName: "Home",
      menuLink: "/",
    },
    {
      menuName: "Blogs",
      menuLink: "/blogs/",
    },
  ]);

  useEffect(() => {
    const updatedMenuList = menuList.map((item) =>
      item.menuName === "Study" ? { ...item, menuLink: getStudyLink() } : item
    );
    setMenuList(updatedMenuList);
  }, [studyState]);






  const [scrollDirection, setScrollDirection] = useState(null);
  const [pageYOffset, setPageYOffset] = useState(0)

  useEffect(() => {

    let lastScrollPosition = window.pageYOffset;


    const handleScroll = () => {
      setIsMenuOpen(false)

      setPageYOffset(window.pageYOffset)

      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > 10) {

        if (currentScrollPosition > lastScrollPosition) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
      }
      else {
        setScrollDirection(null);
      }
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener('scroll', handleScroll);




    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []); // Empty dependency array ensures this effect runs only once


  return (
    <>

      <nav className={` w-full h-16 z-50 transition-all duration-700 sm:duration-75 ease-in-out sm:h-20 border-none ${pageYOffset < 20 ? "fixed" : "navbg"} ${scrollDirection === "down" ? "hidden fixed" : scrollDirection === "up" ? "flex fixed" : "flex"}   justify-between  items-center px-3 sm:px-6 md:px-10 lg:px-14 xl:px-24 2xl:px-32 `}>
        <div className="flex items-center gap-2 lg:gap-9 shadow-none" data-aos="fade-down" >
          <Button
            className={`sm:hidden cursor-pointer bg-transparent w-8 sm:w-11 h-9 sm:h-12 shadow-none bg-center bg-no-repeat rounded-md transition-all hover:shadow-none duration-75 ease-in-out`}
            style={{
              backgroundImage: `${theme ? `url(${homeMenu})` : `url(${homeMenuDark})`}`,
              backgroundSize: "2rem 2rem",
            }}
            onClick={openDrawer}
          > </Button>
          <Link
            className="hidden sm:block w-11 h-12 cursor-pointer  bg-transparent shadow-none bg-center bg-no-repeat rounded-md"
            style={{
              backgroundImage: `url(${logo})`,
              backgroundSize: "3.3rem 3.3rem",
            }}
            to="/"
          ></Link>
          <Link
            className="text-[1.1rem] sm:text-lg lg:text-xl dark:text-white"
            style={{ fontFamily: "'Mochiy Pop P One', sans-serif" }}
            to="/"
          >
            {title}
          </Link>
        </div>

        <ul
          className="hidden sm:flex sm:justify-center sm:gap-5 lg:gap-16 2xl:gap-25 font-bold text-lg dark:text-white"
          data-aos="fade-down"
          style={{ fontFamily: "'Lato', sans-serif" }}
        >
          {menuList.map((value, index) => (
            <Link to={value.menuLink} key={index}>
              <li>{value.menuName}</li>
            </Link>
          ))}
        </ul>

        <NavbarProfile isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </nav>
      <MobileSliderMenu open={open} closeDrawer={closeDrawer} />
    </>
  );
};

export default Navbar;

import React from "react";
import Ellipse1 from "../../../assets/backgrounds/desktop.png";
import Ellipse2 from "../../../assets/backgrounds/dark.png";
import Left from "./Left";
import Right from "./Right";
import { useSelector } from "react-redux";

const Header = ({content}) => {
  const theme = useSelector(state => state.theme)
  return (
    <header 
      className="w-full h-full lg:h-screen pt-16 lg:pt-14 flex flex-col lg:flex-row xl:px-10 2xl:px-16 dark:bg-[#0f172a]"
      style={{
        background: `${theme?`url(${Ellipse1})`:`url(${Ellipse2})`}`,
        backgroundPosition: "right 0em",
        backgroundRepeat: "no-repeat",
        backgroundSize: `${theme?`100% 100%`:`100% 500%`}`,
      }}
    >
      <Left content={content}/>
      <Right />
    </header>
  );
};

export default Header;

import {
  Card,
  CardBody,
  Typography
} from "@material-tailwind/react";

const IconImageCards = ({ title, msg, img }) => {
  return (
    <Card data-aos="fade-up" className="mt-6 cursor-pointer w-[19rem] md:w-[20.6rem] h-[23rem] flex justify-center items-center border-b-[#3063F1] border-b-8 transition-all duration-300 hover:scale-105 dark:bg-[#0f1115]">
      <CardBody className="flex flex-col justify-center align-middle flex-1 items-center gap-y-4 blue-gray dark:text-[#ffffff]">
        <img className="w-16 h-16" src={img} alt="img" loading="lazy" />
        <Typography variant="h5" className="mb-2">
          {title}
        </Typography>
        <Typography className="text-center blue-gray dark:text-[#e4e4e4]">
          {msg}
        </Typography>
      </CardBody>
    </Card>
  );
}

export default IconImageCards
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserActionCreators, ProfileActionCreators } from "../state/index";
import { bindActionCreators } from "redux"
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import useAlert from "./../hooks/useAlert";
import { isSupported } from 'firebase/messaging'
import { getFCMToken } from "../firebaseConfig"

const useFetch = (url, redirectURL) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let { showAlert } = useAlert();

  const dispatch = useDispatch();
  const { setUser } = bindActionCreators(UserActionCreators, dispatch)
  const { updateProfileLogin } = bindActionCreators(ProfileActionCreators, dispatch)
  const notificationState = useSelector(state => state.Notification)


  const handleGoogle = async (response) => {
    console.log(response)
    setLoading(true);

    const send = { credential: response.credential }
    if (notificationState == null) {
      if (isSupported()) {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
          const token = await getFCMToken();
          send.fcmToken = token
          console.log(send)
        }
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(send),
    })
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        if (data?.token) {
          const decoded = jwtDecode(data.token)
          setUser({ token: data.token, user: decoded })
          navigate(redirectURL)
          showAlert("green", "User login successfully..!", 5000)
          updateProfileLogin()
        }
        else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        showAlert("red", error?.message, 5000)
        setLoading(false)
      })
  }
  return { loading, handleGoogle }
}

export default useFetch;
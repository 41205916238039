import React, { useEffect, useState } from 'react'
import { Button } from "@material-tailwind/react";
import { useSelector, useDispatch } from "react-redux";
import { PrivacyActionCreators } from "../../../state/index";
import { bindActionCreators } from "redux"
import { Link } from 'react-router-dom';

const PrivacyPoup = () => {
    const privacy = useSelector(state => state.privacy)
    const dispatch = useDispatch();
    const { setPrivacy } = bindActionCreators(PrivacyActionCreators, dispatch)
    const [tempClose, setTempClose] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setTempClose(false)
        }, 5000)
    }, [])
    return (
        <div id="info-popup" tabindex="-1" class="overflow-y-auto z-50 overflow-x-hidden fixed bottom-1">
            <div class={`relative p-5 w-full max-w-lg h-full md:h-auto ${privacy || tempClose ? "hidden" : ""}`}>
                <div class="relative p-5 privacy rounded-lg shadow  md:p-8 shadow-xl">
                    <div class="mb-4 text-sm  font-light text-gray-500 dark:text-gray-400">
                        <h3 class="mb-3 text-xl sm:text-2xl font-bold text-black dark:text-white" style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}>Privacy info</h3>
                        <p className='text-black text-sm sm:text-base dark:text-white' style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}>
                            Your privacy matters! We use cookies to enhance your experience on MSBTEWallah. By continuing, you agree to our Privacy Policy. </p>
                    </div>
                    <div class="justify-between w-full items-center pt-0 space-y-4 sm:flex sm:space-y-0">
                        <Link to="/pages/privacy-policy"><p onClick={() => {
                            setTempClose(true)
                        }} class="font-medium text-primary-600 dark:text-primary-500 hover:underline text-sm sm:text-base text-black dark:text-white">Learn more about privacy</p> </Link>
                        <div class="flex gap-x-3 justify-start">
                            <Button variant="outlined" className='dark:text-white dark:border-white' onClick={() => {
                                setPrivacy(true)
                            }}>Cancel</Button>
                            <Button className='bg-[#3063F1] margin-0' onClick={() => {
                                setPrivacy(true)
                            }}>confirm</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPoup
import { CurrentPageActionCreators } from "../state/index";
import { bindActionCreators } from "redux"
import { useSelector, useDispatch } from "react-redux";

const useCurrentPage = () =>{
    const dispatch = useDispatch();
    const { setCurrentPage } = bindActionCreators(CurrentPageActionCreators, dispatch)
    const currentPage = useSelector(state => state.currentPage)
    const setCurrentPageName = (name) =>{
        setCurrentPage(name)
    }
    return {currentPage, setCurrentPageName}
}

export default useCurrentPage
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import Lottie from "react-lottie-player"
import loading from "../../../assets/lottie_files/notFoundAnimation.json"
import BlogCard from '../../../components/cards/blogCard/BlogCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography, Input, Button } from "@material-tailwind/react"
import BlogEditModel from '../../../components/modal/blog/BlogEditModel'
import { useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';

const BlogsContainer = ({ loadAgain, setLoadAgain }) => {


  const theme = useSelector(state => state.theme)

  const [resourcesList, setResourcesList] = useState([])

  const [active, setActive] = useState(1)
  const [limit, setLimit] = useState(3)
  const [totalRecords, setTotalRecords] = useState(0)
  const [firstLoad, setFirstLoad] = useState(false)
  const [search, setSearch] = useState("")

  const [open, setOpen] = useState(false)


  const [editId, setEditId] = useState("")
  const [editTitle, setEditTitle] = useState("")
  const [editContent, setEditContent] = useState("")
  const [editCategory, setEditCategory] = useState("")
  const [editSlug, setEditSlug] = useState("")
  const [editDescription, setEditDescription] = useState("")
  const [editKeywords, setEditKeywords] = useState("")
  const [editFile, setEditFile] = useState("")

  let sortOptions = useMemo(() => { return { createdAt: -1 } })


  const handleOpen = (id, title, category, content, slug, description, keywords, file) => {
    setOpen(!open)
    setEditId(id)
    setEditTitle(title)
    setEditCategory(category)
    setEditContent(content)
    setEditSlug(slug)
    setEditDescription(description)
    setEditKeywords(keywords)
    setEditFile(file)
  }



  const fetchAllResources = async (blogSearch) => {
    try {
      let url;
      if (blogSearch) {
        url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/search?title=${search}&sortOptions=${JSON.stringify(sortOptions)}`
      } else {
        url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/?page=${1}&limit=${limit}&sortOptions=${JSON.stringify(sortOptions)}`
      }

      const res = await axios.get(url)
      setTotalRecords(res.data.totalRecords)
      setResourcesList(res.data.data)

    } catch (e) {
      setResourcesList([])
      setTotalRecords(0)
      setActive(0)
    }
  }


  const fetchMoreResources = async (blogSearch) => {
    try {
      setActive(active + 1)

      let url;

      if (blogSearch) {
        url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/search?title=${search}&sortOptions=${JSON.stringify(sortOptions)}`
      }
      {
        url = `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/?page=${active + 1}&limit=${limit}&sortOptions=${JSON.stringify(sortOptions)}`
      }

      const res = await axios.get(url)
      let newArr = resourcesList.concat(res.data.data)
      setResourcesList(newArr)

    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    setActive(1)
    fetchAllResources(false)
    setFirstLoad(true)
  }, [loadAgain])




  return (
    <div className='p-5 flex flex-col w-full h-full items-center'>
      <div className='w-full flex justify-center mt-0 h-full flex-col md:flex-row'>
        <div className='flex gap-5 flex-col md:flex-row'>
          <div>
            <Input required size='lg' type='text' value={search} onChange={(e) => setSearch(e.target.value)} className='w-full md:w-80 h-full' label="Search" autoComplete='off' color={theme ? `gray` : 'white'} />
          </div>
          <Button size='sm' onClick={() => {
            setActive(1)
            fetchAllResources(true)
          }} className='w-full' variant="outlined" color={theme ? `gray` : 'white'}><SearchIcon /></Button>
        </div>
      </div>
      <BlogEditModel open={open} handleOpen={handleOpen} loadAgain={loadAgain}
        setLoadAgain={setLoadAgain}
        editId={editId}
        editTitle={editTitle}
        setEditTitle={setEditTitle}
        editCategory={editCategory}
        setEditCategory={setEditCategory}
        editContent={editContent}
        setEditContent={setEditContent}
        editSlug={editSlug}
        setEditSlug={setEditSlug}
        editDescription={editDescription}
        setEditDescription={setEditDescription}
        editKeywords={editKeywords}
        setEditKeywords={setEditKeywords}
        editFile={editFile}
        setEditFile={setEditFile}
      />



      <Typography className=" flex justify-center capitalize mb-10 blue-gray mt-5 dark:text-[#e4e4e4] font-ligh ">Total : {totalRecords}</Typography>

      <InfiniteScroll
        dataLength={resourcesList.length}
        next={() => {
          fetchMoreResources(false)
        }}
        hasMore={resourcesList.length !== totalRecords}
        loader={<div className='w-full overflow-hidden flex justify-center items-center mb-5 mt-5'>
          <Spinner color="blue" className='w-16 h-16' />
        </div>}
      >
        <div className='w-full h-full flex flex-wrap gap-14 justify-center min-w-screen min-h-[50vh] pt-10 pb-24'>
          {
            (resourcesList.length === 0) ? <Lottie
              loop
              animationData={loading}
              play
              className="w-full min-h-[19rem] h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
            /> :
              resourcesList.map((data, key) => {
                return (<BlogCard
                  key={key}
                  data={data}
                  cardHeight={"40rem"}
                  edit={handleOpen}
                />)
              })
          }
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default BlogsContainer
import React, { useEffect } from 'react'
import useCurrentPage from '../../../hooks/useCurrentPage'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react"
import BlogsContainer from './BlogsContainer'
import AddNewBlog from './AddNewBlog'


const AddBlog = () => {



  let { setCurrentPageName } = useCurrentPage();
  const [loadAgain, setLoadAgain] = React.useState(false)

  const [activeTab, setActiveTab] = React.useState("myUploads")

  useEffect(() => {
    setCurrentPageName("Add Branch")
  }, [])


  const data = [
    {
      label: "Blogs",
      value: "myUploads",
      desc: <BlogsContainer  loadAgain={loadAgain} setLoadAgain={setLoadAgain}  />,
    },
    {
      label: "Post Blog",
      value: "uploadNew",
      desc: <AddNewBlog  loadAgain={loadAgain} setLoadAgain={setLoadAgain}  />,
    }
  ];



  return (
    <div className='cpBg dark:bg-[#0f1115] border-none py-5'>
      <Tabs value={activeTab}>
        <TabsHeader
          className="rounded-none border-b dark:bg-[#0f1115] border-blue-gray-50 bg-transparent felx justify-center items-center p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none dark:bg-[#0f1115]",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={activeTab === value ? "text-gray-900 dark:bg-[#0f1115] dark:text-white pb-5" : "dark:bg-[#0f1115] dark:text-[#ffffff8d] pb-5"}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value} className='flex px-0 justify-center items-center'>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default AddBlog
import React from 'react'
import IntoductionVideoFile from "../../../assets/videos/msbte_wallah_introduction_video.mp4"
import IntoductionVideoFileThumbnail from "../../../assets/videos/msbte_wallah_introudction_video_thumbnail.jpg"

const IntoductionVideo = () => {
    return (
        <div className="flex flex-col justify-between gap-y-10 sm:px-5 md:px-10 py-5 items-center" data-aos="fade-up" >
            <video className="h-full w-full sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] rounded-lg"
                onMouseEnter={(e) => e.target.controls = true}
                onMouseOut={(e) => e.target.controls = false}
                poster={IntoductionVideoFileThumbnail}>
                <source src={IntoductionVideoFile} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default IntoductionVideo
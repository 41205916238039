import React from 'react'
import CountUp from 'react-countup';

const StatsCard = ({ no, text1, text2, value, suffix }) => {
  return (
    <div data-aos="fade" className={`flex w-40 flex-col justify-center items-center align-middle gap-2 xl:gap-5   ${no === 1 ? "border-x-2 px-[1rem] sm:px-[3rem] md:px-[5rem]  xl:px-[12rem]" : ""} lg:py-12  border-white  text-white font-medium  font-BalooBhaina2 text-base sm:text-xl   tracking-wider`}>
      <span className='text-xl sm:text-2xl lg:text-3xl font-bold'><CountUp end={value} duration={7} suffix={suffix} /></span>
      <p className='text-center'>

        {text1}
        <br />
        <span className='underline   decoration-2 decoration-white'>
          {text2}
        </span>
      </p>
    </div>
  )
}

export default StatsCard
import AOS from 'aos'

const InitializeAOS = () =>{
    AOS.init({
        offset: 50, // offset (in px) from the original trigger point
        delay: 50, // values from 0 to 3000, with step 50ms
        duration: 1000,
        once: true, 
        mirror: true,
    })
}

export default InitializeAOS
const CircularImg = ({src}) => {
    return (
      <img
        className="h-full w-full rounded-full object-cover object-center"
        src={src}
        alt="nature image"
        referrerPolicy="no-referrer"
      />
    );
  }

export default CircularImg
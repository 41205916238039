import React, { useEffect, useState } from 'react'
import { Input } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { Button } from '@material-tailwind/react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import useAuth from '../../../../hooks/useAuth';
import axios from 'axios';
import useAlert from "../../../../hooks/useAlert";
import { Select, Option } from "@material-tailwind/react";


const BranchUpload = ({ loadAgain, setLoadAgain }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert()

    const theme = useSelector(state => state.theme)

    const [branchesList, setBranchesList] = useState([])
    const [schemesList, setSchemesList] = useState([])
    const [semestersList, setSemestersList] = useState([])
    const [listBranch, setListBranch] = useState([])
    const [schemeList, setSchemeList] = useState([])
    const [branch, setBranch] = useState("")
    const [scheme, setScheme] = useState("")
    const [semester, setSemester] = useState("")
    const [subjectName, setSubjectName] = useState("")
    const [subjectCode, setSubjectCode] = useState("")


    const fetchBranches = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/branches`
            )
            setBranchesList(result.data)
        }
        catch (e) {
            console.log(e)
        }
    }

    const postSubject = async (e) => {
        e.preventDefault()
        try {
            if (scheme != "" && semester != "" && subjectName != "" && subjectCode != "" && subjectName != "" && listBranch.length != 0) {
                const result = await axios.post(
                    `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/subject`,
                    {
                        branchID: listBranch,
                        name: subjectName,
                        code: subjectCode,
                        scheme: schemeList,
                        semester: semester
                    }
                    ,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserInfo().token}`
                        }
                    }
                )

                console.log(result)
                showAlert("green", "Subject Added Successfully...!", 5000)
                // fetchBranches()
                setLoadAgain(!loadAgain)
            }
        }
        catch (e) {
            showAlert("red", "something went wrong...!", 5000)
        }
    }

    const addBranch = () => {
        setListBranch([...new Set([...listBranch, branch])])
    }

    const clearAllBranches = () => {
        setListBranch([])
    }

    const addScheme = () => {
        setSchemeList([...new Set([...schemeList, scheme])])
    }

    const clearAllScheme = () => {
        setSchemeList([])
    }



    useEffect(() => {
        fetchBranches()
    }, [])


    return (
        <form className='w-full gap-5 items-center h-full flex flex-col px-5 xl:px-20 pt-10' onSubmit={postSubject}>

            <div className="w-full flex gap-5 justify-center items-center flex-wrap">
                <Select onChange={(e) => {
                    let data = branchesList.find((val) => val._id == e)
                    setSchemesList(data.schemes)
                    setSemestersList(data.semesters)
                    setBranch(e)
                }} required label="Branch" style={{ color: theme ? "gray" : "white" }} className='capitalize' >
                    {branchesList.map((value, key) => <Option key={key} className='capitalize' value={value._id}>{value.branchName}</Option>)}
                </Select>
                <Button color="blue" onClick={addBranch}><AddIcon /></Button>
                <Button color="blue" onClick={clearAllBranches}><ClearIcon /></Button>
            </div>

            <div className='w-full'>
                {listBranch.map((val, index) => {
                    return (<p className="text-center dark:text-white" key={index}>{index + 1} : {val}</p>)
                })}
            </div>

            <div className="w-full flex gap-5 justify-center items-center flex-wrap">
                <Select onChange={(e) => {
                    setScheme(e)
                }} required label="Scheme" style={{ color: theme ? "gray" : "white" }} className='capitalize' >
                    {schemesList.map((value, key) => <Option key={key} className='capitalize' value={value}>{value}</Option>)}
                </Select>
                <Button color="blue" onClick={addScheme}><AddIcon /></Button>
                <Button color="blue" onClick={clearAllScheme}><ClearIcon /></Button>
            </div>

            <div className='w-full'>
                {schemeList.map((val, index) => {
                    return (<p className="text-center dark:text-white" key={index}>{index + 1} : {val}</p>)
                })}
            </div>

            <div className="w-full ">
                <Select onChange={(e) => {
                    setSemester(e)
                }} required label="Semester" style={{ color: theme ? "gray" : "white" }} className='capitalize' >
                    {semestersList.map((value, key) => <Option key={key} className='capitalize' value={value}>{value}</Option>)}
                </Select>
            </div>

            <Input required size="lg" type='text' label="Subject Name" onChange={(e) => { setSubjectName(e.target.value) }} value={subjectName} autoComplete='off' color={theme ? `gray` : 'white'} />
            <Input required size="lg" type='text' label="Subject Code" onChange={(e) => { setSubjectCode(e.target.value) }} value={subjectCode} autoComplete='off' color={theme ? `gray` : 'white'} />
            <Button color="blue" type='submit'>Add</Button>
        </form>
    )
}

export default BranchUpload
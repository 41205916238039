import React from 'react'
import Lottie from "react-lottie-player";
import LoginLottie from "../../../assets/lottie_files/login.json"
import { Spinner } from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import useFetch from "./../../../hooks/useFetch";
import { useEffect } from 'react'


function Login() {

  const { handleGoogle, loading } = useFetch(
    `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/login`,
    "/"
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "pill",
      });

    }
  }, [handleGoogle]);

  return (
    <div className='w-full h-full flex sm:flex-row flex-col flex-wrap justify-between bg-[#d4e0ff55] dark:bg-[#0f172a8a] '>
      <div className='w-full hidden sm:w-[60%] h-screen sm:flex items-center justify-center'  >
        <Lottie
          loop
          animationData={LoginLottie}
          play
          className="w-full h-full lg:w-[28rem] lg:h-[28rem] xl:w-[36rem] xl:h-[36rem] z-0"
        />
      </div>
      <div className='w-full sm:w-[40%] h-screen bg-white flex items-center justify-center flex-col gap-y-20'>
        <Typography variant="h3" style={{ fontFamily: "'Mochiy Pop P One', sans-serif" }}><span className='text-[#3063F1]'>Msbte</span> Wallah</Typography>

        <div className='flex justify-center items-center flex-col gap-y-11'>
          <Typography variant="h3" style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}>Login</Typography>

          {loading ? (
            <Spinner className="h-12 w-12" color="blue" />
          ) : (
            <div id="signUpDiv" data-text="signup_with"></div>
          )}

          <p className='text-sm text-center leading-7  font-normal text-gray-600'>
            By continuing, you agree to Company’s
            <br /><b><Link to="/pages/term-of-use" className='cursor-pointer'>Terms of Use</Link></b> and <b><Link to="/pages/term-of-use" className='cursor-pointer'>Privacy Policy.</Link></b>
          </p>
          <Typography color="gray" className="mt-4 text-center font-normal">
            Don’t you have an account?
            <Link to="/auth/signUp" className="font-medium text-gray-900 ml-1">
              Sign up
            </Link>
          </Typography>

          <Link className='bg-transparent text-gray-800' to="/">
            <HomeIcon className='text-gray-600' />
          </Link>

        </div>

      </div>
    </div>
  )
}

export default Login
import React from "react";
import {
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const MobileSideBarMenuItemAccordion = ({
  name,
  svg,
  items,
  open1,
  handleOpen,
  link,
  closeDrawer
}) => {
  const Icon = ({ id, open }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""
          } h-5 w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    );
  };
  return (
    <Accordion
      open={open1 === 1}
      icon={<Icon id={1} open={open1} />}
      className="px-0 my-3 "
    >
      <ListItem>
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="h-4 border-none justify-between py-2 pt-3 text-black dark:text-white"
        >
          <div className="flex text-[16px] font-normal">
            <ListItemPrefix>{svg}</ListItemPrefix>
            {name}
          </div>
        </AccordionHeader>
      </ListItem>
      <AccordionBody className="felx gap-y-3 py-1">
        {items.map((obj) => (
          <Link to={obj.link} key={obj.name} onClick={closeDrawer}>
            <ListItem
              className="focus:bg-white active:bg-white ml-5 text-black dark:text-white"
            >
              <ListItemPrefix>{obj.svg}</ListItemPrefix>
              {obj.name}
            </ListItem>
          </Link>
        ))}
      </AccordionBody>
    </Accordion>
  );
};

export default MobileSideBarMenuItemAccordion;

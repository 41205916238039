import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function GoogleAd({ slot, googleAdId, layoutStyle }) {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, []);

    return (
        <div>
            {layoutStyle === "in-article" ?
                <ins
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client={googleAdId}
                    data-ad-slot={slot}
                    data-full-width-responsive="true"
                ></ins>
                :
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client={googleAdId}
                    data-ad-slot={slot}
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            }
        </div>
    );
}

GoogleAd.propTypes = {
    slot: PropTypes.string.isRequired,
    googleAdId: PropTypes.string.isRequired,
};

export default GoogleAd;

import React, { useState, useRef, useMemo } from 'react'
import JoditEditor from 'jodit-react';
import '../../../assets/styles/dashboard/editor.css'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth'
import { Button as TailWindButton, Input, Spinner } from "@material-tailwind/react"
import { useSelector } from "react-redux"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Button from '@mui/material/Button';
import useAlert from '../../../hooks/useAlert';

const AddNewBlog = ({ loadAgain, setLoadAgain }) => {
  const editor = useRef(null);
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [keywords, setKeywords] = useState('')
  const [file, setFile] = useState(null)
  const [slug, setSlug] = useState('')

  const [send, setSend] = React.useState(false)


  const { getUserInfo } = useAuth()
  let { showAlert } = useAlert()

  const theme = useSelector(state => state.theme)


  const config = {
    height: 600,
    uploader: {
      insertImageAsBase64URI: true, // Set to true if you want to insert images as base64 URIs
    },
  };



  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    } else {
      // Reset file state if an invalid file is selected
      setFile(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (send === false && title && content && category && description && keywords && file) {
        setSend(true)

        const formData = new FormData()
        formData.append('file', file)

        const data = JSON.stringify({
          title,
          content,
          category,
          description,
          keywords,
          slug
        })

        formData.append('data', data)
        await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/blog/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${getUserInfo().token}`
            }
          })
        showAlert("green", "Resource uploaded successfully..!", 5000)
        setSend(false)
        setLoadAgain(!loadAgain)
      }
    }
    catch (e) {
      if (e?.response?.data?.message) {
        console.log(e.response)
        showAlert("red", e.response.data.message, 5000)
      } else {
        showAlert("red", "something went wrong...!", 5000)
      }
    }
    setSend(false)
  }

  return (
    <form className='w-full flex flex-col px-5 md:px-10' onSubmit={handleSubmit}>
      <div className='flex py-5 gap-5'>
        <Input required size="lg" type='text' label="Title" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete='off' color={theme ? `gray` : 'white'} />

      </div>
      <div className='w-full'>
        <JoditEditor
          config={config}
          ref={editor}
          value={content}
          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={newContent => { }}
        />
      </div>

      <div className='flex py-5 gap-5 flex-col md:flex-row dark:text-white'>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="4" className="w-full h-56 focus:outline-none rounded-md p-5 dark:bg-[#0f1115] dark:border-white border-gray-400 border-[1px] dark:focus:border-2  " placeholder="Write Description Here"></textarea>
        <textarea value={keywords} onChange={(e) => setKeywords(e.target.value)} rows="4" className="w-full h-56 focus:outline-none rounded-md p-5 dark:bg-[#0f1115] dark:border-white border-gray-400 border-[1px] dark:focus:border-2  " placeholder="Write Keywords Here"></textarea>
      </div>
      <div>
        <div className='flex justify-center w-full'
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Button
            component="label"
            variant="contained"
            className='bg-[#3063f1] h-14'
            startIcon={<CloudUploadIcon />}
          >
            {file == null ? "Poster" : file?.name.length > 50 ? file?.name.substr(0, 50) : file?.name}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </Button>
        </div>

      </div>
      <div className='py-5 flex justify-center gap-5 mt-5 md:justify-between flex-col lg:flex-row'>
        <div className='flex gap-5 flex-col lg:flex-row justify-center items-center'>
          <div className="flex w-full lg:w-52 h-full items-center flex-col justify-center gap-6">
            <Input required size='md' type='text' label="Category" autoComplete='off' value={category} onChange={(e) => { setCategory(e.target.value) }} color={theme ? `gray` : 'white'} />
          </div>
          <div className="flex w-full  lg:w-72 h-full items-center flex-col justify-center gap-6">
            <Input required size='md' type='text' label="Slug" autoComplete='off' value={slug} onChange={(e) => { setSlug(e.target.value) }} color={theme ? `gray` : 'white'} />
          </div>
        </div>

        <TailWindButton size='sm' className="capitalize px-8 py-3 text-[0.9rem] h-full tracking-wider bg-[#3063F1]" type='submit'>
          {send ? <Spinner className="h-6 w-7 text-white" /> : "Post"}
        </TailWindButton>
      </div>
    </form >
  );
};


export default AddNewBlog
import DashboardRouter from "./pages/dashboard/DashboardRouter";
import HomeRouter from "./pages/app/home/HomeRouter";
import PagesRouter from "./pages/app/pages/PagesRouter";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import InitializeAOS from "./utils/InitializeAOS";
import "aos/dist/aos.css";
import AuthRouter from './pages/app/Auth/AuthRouter'
import AlertPopUp from './components/alert/AlertPopUp'
import { useSelector, useDispatch } from "react-redux";
import { ThemeActionCreators, NotificationActionCreators } from "./state/index"
import { bindActionCreators } from "redux"
import { useEffect } from "react";
import React from "react";
import { messaging } from "./firebaseConfig"
import axios from "axios";
import { onMessage } from 'firebase/messaging';
import PrivacyPoup from "./components/modal/privacy_popup/PrivacyPoup";
import BlogRouter from "./pages/blog/BlogRouter";

function App() {

  const theme = useSelector(state => state.theme)
  const dispatch = useDispatch();
  const { setTheme } = bindActionCreators(ThemeActionCreators, dispatch)
  const { setNotification } = bindActionCreators(NotificationActionCreators, dispatch)
  const notificationState = useSelector(state => state.Notification)
  const user = useSelector(state => state.user)

  onMessage(messaging, payload => {

    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      data: payload, // Include the payload in the notification data
      icon: payload.notification.image,
    };
    console.log(payload)
    navigator.serviceWorker.ready
      .then((registration) => {
        // Check if the browser supports notifications
        if ('Notification' in window) {
          // Check the current notification permission
          if (Notification.permission === 'granted') {
            // If permission is granted, show the notification
            registration.showNotification(notificationTitle, notificationOptions);
          } else if (Notification.permission !== 'denied') {
            // If permission is not denied, request permission
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                // If permission is granted, show the notification
                registration.showNotification(notificationTitle, notificationOptions);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error while trying to show notification:', error);
      });
  })

  let detectTheme = () => {
    if (theme === null) {
      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.body.classList.add("dark")
          setTheme(false)
        }
      }
      else {
        document.body.classList.add("dark")
        setTheme(false)
      }
    }
    if (theme === false) {
      document.body.classList.add("dark")
      setTheme(false)
    }

  }

  const updateFCM = async (token) => {
    try {
      if (user != null) {
        await axios.patch(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/auth/update`,
          {
            fcmToken: token
          },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`
            }
          })
        setNotification(null)
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const incrementInstallCount = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/counters/incrementInstallCount`)
    }
    catch (e) {
    }
  }

  const incrementVisitorCount = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/counters/incrementVisitorCount`)
    }
    catch (e) {
    }
  }

  useEffect(() => {
    if (notificationState != null) {
      updateFCM(notificationState)
    }
  }, [notificationState])


  useEffect(() => {
    detectTheme()
  }, [theme])

  window.addEventListener('appinstalled', (event) => {
    incrementInstallCount()
  });


  useEffect(() => {
    detectTheme()
    incrementVisitorCount()
  }, [])


  InitializeAOS();

  return (
    <div className="App dark:bg-[#17233f]">
      <BrowserRouter>
        <Routes>
          <Route path="/*" exact element={<HomeRouter />} />
          <Route path="/blogs/*" exact element={<BlogRouter />} />
          <Route path="/auth/*" exact element={<AuthRouter />} />
          <Route path="/pages/*" exact element={<PagesRouter />} />
          <Route path="/dashboard/*" exact element={<DashboardRouter />} />
        </Routes>
        <PrivacyPoup />
      </BrowserRouter>
      <AlertPopUp />

    </div>
  );
}

export default App;

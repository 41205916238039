import React, { useRef } from 'react'
import JoditEditor from 'jodit-react';
import '../../../assets/styles/dashboard/editor.css'
import { Input } from "@material-tailwind/react"
import { useSelector } from "react-redux"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Button from '@mui/material/Button';


const BlogWriter = ({update, editTitle, setEditTitle, editCategory, setEditCategory, editContent, setEditContent, editSlug, setEditSlug, editDescription, setEditDescription, editKeywords, setEditKeywords, file, setFile }) => {
    const editor = useRef(null);
 

    const theme = useSelector(state => state.theme)


    const config = {
        height: 600,
        uploader: {
            insertImageAsBase64URI: true, // Set to true if you want to insert images as base64 URIs
        },
    };



    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setFile(selectedFile);
        } else {
            // Reset file state if an invalid file is selected
            setFile(null);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    };


    return (
        <form className='w-full flex flex-col md:px-10' onSubmit={update}>
            <div className='flex py-5 gap-5'>
                <Input required size="lg" type='text' label="Title" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} autoComplete='off' color={theme ? `gray` : 'white'} />

            </div>
            <div className='w-full'>
                <JoditEditor
                    config={config}
                    ref={editor}
                    value={editContent}
                    onBlur={newContent => setEditContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { }}
                />
            </div>

            <div className='flex py-5 gap-5 flex-col md:flex-row dark:text-white'>
                <textarea value={editDescription} onChange={(e) => setEditDescription(e.target.value)} rows="4" className="w-full resize-none h-56 focus:outline-none rounded-md p-5 dark:bg-[#0f1115] dark:border-white border-gray-400 border-[1px] dark:focus:border-2  " placeholder="Write Description Here"></textarea>
                <textarea value={editKeywords} onChange={(e) => setEditKeywords(e.target.value)} rows="4" className="w-full resize-none h-56 focus:outline-none rounded-md p-5 dark:bg-[#0f1115] dark:border-white border-gray-400 border-[1px] dark:focus:border-2  " placeholder="Write Keywords Here"></textarea>
            </div>

            <div className='py-5 flex justify-center flex-col md:flex-row gap-5 mt-5 md:justify-between flex-wrap'>
                <div className='flex gap-5 flex-col md:flex-row'>
                    <div className="flex w-full md:w-52 h-full items-center flex-col justify-center gap-6">
                        <Input required size='md' type='text' label="Category" autoComplete='off' value={editCategory} onChange={(e) => { setEditCategory(e.target.value) }} color={theme ? `gray` : 'white'} />
                    </div>
                    <div className="flex w-full md:w-72 h-full items-center flex-col justify-center gap-6">
                        <Input required size='md' type='text' label="Slug" autoComplete='off' value={editSlug} onChange={(e) => { setEditSlug(e.target.value) }} color={theme ? `gray` : 'white'} />
                    </div>
                </div>
                <div className='flex justify-center '
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <Button
                        component="label"
                        variant="contained"
                        className='bg-[#3063f1] h-14'
                        startIcon={<CloudUploadIcon />}
                    >
                        {file == null ? "Poster" : file?.name.length > 50 ? file?.name.substr(0, 50) : file?.name}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </Button>
                </div>
            </div>
        </form >
    );
};


export default BlogWriter
import React from 'react'
import Lottie from "react-lottie-player"
import Error404 from "../../../../assets/lottie_files/Error404.json"

const NotFound = () => {
    return (
        <div className='w-full min-h-screen max-h-full flex justify-center px-28 p-24'>
            <div className='w-full max-h-full flex justify-center items-center rounded-lg'>
                <Lottie
                    loop
                    animationData={Error404}
                    play
                    className="w-full h-[50vh] lg:w-[15rem] lg:h-[80vh] xl:w-[20rem] xl:h-[60vh] z-0"
                />
            </div>
        </div>
    )
}

export default NotFound
import React, { useEffect } from 'react'
import HeaderSection from './HeaderSection'
import LearnMoreSction from './LearnMoreSction'
import CommunitySection from './CommunitySection'
import Explore from './Explore'
import Feedback from './Feedback'
import Footer from './Footer'
import useAuth from '../../../hooks/useAuth'
import Seo from '../../../components/seo/Seo'
import { HOME_PAGE } from '../../../constants/Seo'


const Home = () => {

  let { getUserInfo } = useAuth()

  const header = {
    mainTitle: {
      l1: { start: 'Learn', end: 'With Msbte' },
      l2: 'Wallah'
    },
    tagline: 'Your Ultimate Guide to Becoming an MSBTE Topper !',
    btn: 'Lets Start'
  }



  return (
    <div className='w-full h-full bg-[#d4e0ff55] dark:bg-[#0f172a]'>
      <Seo
        title={HOME_PAGE.title}
        description={HOME_PAGE.description}
        name={HOME_PAGE.name}
        keywords={HOME_PAGE.keywords}
        type={HOME_PAGE.type} />
      <HeaderSection content={header} />
      <LearnMoreSction />
      {
        !getUserInfo() ? <CommunitySection /> : ""
      }
      <Explore />
      <Feedback />
      <Footer />
    </div>
  )
}

export default Home
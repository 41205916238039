import React, { useState } from 'react'
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
} from "@material-tailwind/react";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth'
import useAlert from '../../../hooks/useAlert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const ResourcesCardForUpload = ({ data, cardHeight, statusList, visibilityList, deleteFromList, edit }) => {

    const { getUserInfo } = useAuth()
    const { showAlert } = useAlert();

    const [approval, setApproval] = useState(data.approval);
    const [visible, setVisible] = useState(data.visible.toString());



    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }


    function formatNumber(number) {
        if (number >= 1e9) {
            return (number / 1e9).toFixed(1) + 'B';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + 'M';
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }


    const deleteCard = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource?id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            deleteFromList(id)
            showAlert("green", "Resource Deleted Successfully...!", 2000)
        }
        catch (e) {
            showAlert("red", "something went wrong", 2000)
        }
    }


    const setNewApproveal = async (val, id) => {
        try {
            let res = await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource-approval?id=${id}`,
                {
                    approval: val
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    },
                })
            showAlert("green", res.data.approval, 2000)
        }
        catch (e) {
            showAlert("red", "something went wrong", 2000)
        }
    }

    const setNewVisibility = async (val, id) => {
        try {
            let res = await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource-approval?id=${id}`,
                {
                    visible: val
                },
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    },
                })
            showAlert("green", res.data.visible ? "visible" : "invisible", 2000)
        }
        catch (e) {
            showAlert("red", "something went wrong", 2000)
        }
    }



    return (
        <Card className={`w-[19rem] h-[${cardHeight}] overflow-hidden dark:bg-[#0f172a] bg-[#f1f2f3]`} >
            <CardBody className={`h-full`} >

                <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize cursor-pointer flex justify-between'>
                    {data?.resourceName.length > 40 ? data?.resourceName.slice(0, 40) + "......" : data?.resourceName}
                    <div className='flex gap-3 items-center  cursor-pointer'>
                        <EditIcon className='dark:text-white' onClick={() => edit(data?.resourceName, data?.type, data?._id)} />
                    </div>
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    id : {data?._id}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    type : {data?.type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    subject : {data?.subject?.name > 40 ? data?.subject?.name.slice(0, 40) + "......" : data?.subject?.name}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    code : {data?.subject?.code?.toUpperCase()}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    branch : {data?.branch?.branchName}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    semester : {data?.semester}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    scheme : {data?.scheme}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    user email : {data?.userEmail}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    user id : {data?.userId}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    createdAt : {formatDate(data?.createdAt)}
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    <p className='mb-3'>approval :</p>
                    <select
                        onChange={(e) => {
                            setApproval(e.target.value)
                            setNewApproveal(e.target.value, data?._id)
                        }}
                        value={approval}
                        className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        {
                            statusList.map((val, key) => {
                                return <option key={key} value={val.value}>{val.name}</option>
                            })
                        }
                    </select>
                </Typography>

                <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                    <p className='mb-3'>visibility :</p>
                    <select
                        onChange={(e) => {
                            setVisible(e.target.value)
                            setNewVisibility(e.target.value, data?._id)
                        }}
                        value={visible}
                        className={`w-28 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-[#0f1115] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                        {
                            visibilityList.map((val, key) => {
                                return <option key={key} value={val.value}>{val.name}</option>
                            })
                        }
                    </select>
                </Typography>

            </CardBody>
            <CardFooter className="flex items-center align-middle justify-between">

                <div className="flex items-center -space-x-3">
                    <Tooltip content={data?.userEmail}>
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="natali craig"
                            src={data?.profilePic}
                            referrerPolicy="no-referrer"
                            className="border-2 border-white hover:z-10"
                        />
                    </Tooltip>

                </div>
                <Link to={data?.type != "videoLecture" ? "/auth/document-viewer/" + data?._id : 'https://www.youtube.com/playlist?list=' + data?.playList[0]}>
                    <div className='flex gap-3 items-center'>
                        <RemoveRedEyeIcon className='dark:text-white' />
                    </div>
                </Link>

                <Typography variant="h6" className="font-normal text-sm capitalize dark:text-white">
                    views : {formatNumber(data?.views)}
                </Typography>
                <Typography variant="h6" className="font-normal text-sm capitalize dark:text-white">
                    likes : {formatNumber(data?.likes.length)}
                </Typography>

                <div className='flex gap-3 items-center cursor-pointer'>
                    <DeleteIcon className='dark:text-white' onClick={() => { deleteCard(data?._id) }} />
                </div>
            </CardFooter>
        </Card >
    )
}

export default ResourcesCardForUpload
import { useSelector, useDispatch } from "react-redux";
import { AlertActionCreators } from "../state/index";
import { bindActionCreators } from "redux";

const useAlert = () => {
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(AlertActionCreators, dispatch);

  const showAlert = (color, msg, time) => {
    setAlert({
      color: color,
      msg: msg,
      open: true
    });

    setTimeout(() => {
      hideAlert();
    }, time);
  };

  const hideAlert = () => {
    setAlert({
      color: "red",
      msg: "",
      open: false
    });
  };


    const alert = useSelector(state => state.alert);


  return { showAlert, hideAlert, alert};
};


export default useAlert
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'

const useAuth = () => {
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const checkSession = () => {
        if (!user) {
            navigate("/auth/signUp")
        }
    }
    const getUserInfo = () => {
        return user
    }
    return { checkSession, getUserInfo }
}

export default useAuth
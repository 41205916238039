import React, { useState } from 'react'
import { Typography } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import axios from 'axios';
import useAlert from '../../../../hooks/useAlert';
import { Spinner } from "@material-tailwind/react";
import Footer from '../../home/Footer';

const ContactUs = () => {
    const theme = useSelector(state => state.theme)
    const { showAlert } = useAlert()
    const {
        handleSubmit, watch, register
    } = useForm();


    const [loading, setLoading] = useState(false)

    const name = watch('name')
    const mobileNo = watch('mobileNo')
    const city = watch('city')
    const email = watch('email')
    const message = watch('message')


    const sendMessage = async (e) => {
        setLoading(true)
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/email/contact`,
                {
                    name,
                    email,
                    city,
                    message,
                    mono: mobileNo
                }
            )

            setLoading(false)
            showAlert("green", result.data.message, 5000)
        }
        catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("red", "something went wrong...!", 5000)
        }
    }


    return (
        <div className='w-full min-h-screen max-h-full flex justify-center  bg-white flex-col dark:bg-[#0f172a] bg-fixed'>
            <div className='w-full flex justify-center pt-15 sm:pt-20 h-60 sm:h-96 items-center  md:pb-12 bg-[#f1f2f3] dark:bg-[#0f172a]'>
                <Typography variant="h1" className='dark:text-white pt-5 text-4xl'>
                    Contact Us
                </Typography>
            </div>
            <div onSubmit={handleSubmit(sendMessage)} className='w-full min-h-screen flex rounded-t-3xl dark:bg-[#0f1115] px-5 py-20 xl:py-20 xl:px-60 flex-col  dark:text-white'>
                <form className='flex px-5 items-start h-full w-full flex-col gap-10 dark:text-white'>
                    <Input size="lg" type='text' required autoComplete='off' label="Name" color={theme ? `gray` : 'white'} {...register('name', { required: true })} />
                    <Input size="lg" type='email' required autoComplete='off' label="Email" color={theme ? `gray` : 'white'} {...register('email', { required: true })} />
                    <Input size="lg" type="tel" required autoComplete='off' pattern="[0-9]*" maxLength="10" label="Mo. No" color={theme ? `gray` : 'white'} {...register('mobileNo', { required: true })} />
                    <Input size="lg" type='text' required autoComplete='off' label="City" color={theme ? `gray` : 'white'} {...register('city', { required: true })} />
                    <div class="w-full">

                        <label for="message" className="block mb-2 text-sm font-medium text-gray-600 dark:text-white">Your message</label>
                        <textarea id="message" required rows="4" className="w-full h-56 focus:outline-none rounded-md p-5 dark:bg-[#0f1115] dark:border-white border-gray-400 border-[1px] dark:focus:border-2  "  {...register('message', { required: true })} placeholder="Write your thoughts here..."></textarea>

                    </div>
                    <div className='flex justify-center md:justify-end w-full'>
                        <Button className="capitalize px-7 bg-[#3063F1] text-sm" type='submit'>{loading ? <Spinner /> : "send"}</Button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs
import React from "react";
import { Button } from "@material-tailwind/react"
import bellIcon from "../../../assets/icons/bellIcon.png"
import { TypeAnimation } from "react-type-animation"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { NotificationActionCreators } from "../../../state/index"
import { bindActionCreators } from "redux"
import useAlert from "../../../hooks/useAlert"
import { isSupported } from 'firebase/messaging'
import { getFCMToken } from "../../../firebaseConfig"


const Left = ({ content: { mainTitle: { l1, l2 }, tagline, btn } }) => {

  const { showAlert } = useAlert()

  const studyState = useSelector(state => state.studyState)
  const seq = [
    // Same substring at the start will only be typed once, initially
    l2,
    1000,
    "",
    1000,
    l2,
    1000,
    l2,
    1000,
  ]


  const dispatch = useDispatch();
  const { setNotification } = bindActionCreators(NotificationActionCreators, dispatch)



  const notification = async () => {
    if (isSupported()) {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        const token = await getFCMToken();
        setNotification(token)
        showAlert("green", "Notification permission enabled", 5000)
      } else {
        showAlert("red", "Notification permission denied", 5000)
      }
    }
  }




  return (
    <div className="w-full h-64 lg:h-full px-6 py-10 sm:px-20 md:justify-center md:items-center lg:px-16 lg:justify-center lg:items-center">
      <div className="flex flex-col gap-y-3 lg:h-full md:items-center md:gap-5 lg:items-start lg:gap-3 lg:justify-center " data-aos="fade-right">
        <h1
          className="font-extrabold text-[2.1rem] lg:text-[3rem] md:text-center lg:text-left xl:text-[3.7rem] dark:text-white"
          style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}
        >
          <span className="text-[#3063F1]" >{l1.start}</span> {l1.end} <br />
          <TypeAnimation
            sequence={seq}
            speed={200}
            repeat={Infinity}
            className="dark:text-white"
          />

        </h1>
        <p
          className="font-medium lg:text-lg dark:text-white"
          style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}
        >
          {tagline}
        </p>
        <div className="flex gap-5 pt-5 items-center">
          <Link to={studyState.branch != null && studyState.scheme != null && studyState.semester != null ? `/pages/study/${studyState.branch}/${studyState.scheme}/${studyState.semester}` : "/pages/scheme"} className="h-full">
            <Button className="capitalize text-[0.9rem] h-full tracking-wider bg-[#3063F1]">
              {btn}
            </Button>
          </Link>
          <Button
            className="capitalize px-3 bg-[#3063F1]"
            style={{ fontFamily: "'Baloo Bhaina 2', cursive" }}
            onClick={() => notification()}
          >
            <img
              loading="lazy"
              src={bellIcon}
              alt="bellIcon"
              className="outline-none border-none h-7 w-7"
              data-aos="zoom-in"
              data-aos-delay="1200"
              data-aos-duration="1000"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Left;
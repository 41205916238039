import React from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { Link } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share'
import moment from 'moment'

const BlogArticalCard = ({ data }) => {


    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }


    const handleShare = async (text, url) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: text,
                    text: text + `\n ` + process.env.REACT_APP_DOMAIN + "/blogs/" + data?.slug
                });
                console.log('Successfully shared!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.warn('Web Share API not supported in this browser.');
            // Handle fallback or provide alternative sharing options
        }
    };

    return (

        <article className='flex shadow-lg shadow-[#dfdbdb49] dark:shadow-none w-[20rem] sm:w-[42rem] items-center h-[9rem] sm:h-[15rem] gap-5 p-5 py-2 sm:p-8  bg-gray-50  dark:bg-[#0f1115] rounded-xl cursor-pointer'>

            <div
                className="h-[6rem] sm:h-full w-[70%] sm:w-[70%] bg-center rounded-lg object-cover object-center"
                style={{
                    background: `url('${data?.posterImg}')`,
                    backgroundSize: "100% 100%"
                }}
                alt="nature image"
            ></div>

            <selection className="flex gap-5 md:gap-5 flex-col w-full  ">
                <Link to={`/blogs/${data?.slug}`} className='flex gap-2 flex-col justify-between '>
                    <h1 className='text-sm sm:text-lg'>{data?.title?.length > 60 ? data?.title?.substr(0, 60) + "........" : data?.title}</h1>
                    <p className='text-sm hidden sm:block' dangerouslySetInnerHTML={{ __html: `${data?.content?.length > 150 ? data?.content?.substr(0, 150) + "........" : data?.content}` }}></p>
                </Link>
                <div className='flex text-sm items-center justify-end text-[0.7rem] sm:text-[1rem] '>

                    <p className='h-full pl-1 flex-col  w-full  align-bottom hidden sm:flex'>{formatDate(data?.createdAt)}</p>

                    <div className='flex sm:gap-4 w-full justify-end md:justify-normal md:w-auto'>
                        <Link to={`/blogs/${data?.slug}`}>
                            <div className='flex gap-3 items-center text-gray-700 mr-5'>
                                <RemoveRedEyeIcon className='dark:text-white' />
                            </div>
                        </Link>
                        <div className='flex gap-3 items-center text-gray-700 cursor-pointer mr-5' onClick={() => handleShare(data?.title)}>
                            <ShareIcon className='dark:text-white' />
                        </div>
                    </div>


                </div>
            </selection>
        </article >
    )
}

export default BlogArticalCard
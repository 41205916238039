import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
} from "@material-tailwind/react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth'
import { Link } from 'react-router-dom';

const ResourceCard = ({ id, resourceName, subject, branch, semester, scheme, createdAt, userEmail, profilePic, type, approval, to, deleteFromList, likes, views, edit }) => {

    const { getUserInfo } = useAuth()

    function formatNumber(number) {
        if (number >= 1e9) {
            return (number / 1e9).toFixed(1) + 'B';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + 'M';
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }

    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const dateObject2 = moment(formattedDate, 'MMM DD, YYYY');
        const formattedDate2 = dateObject2.format('DD MMM YYYY');
        return formattedDate2;
    }

    const deleteCard = async (id) => {
        try {
            await axios.put(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource?id=${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${getUserInfo().token}`
                    }
                })
            deleteFromList(id)
        }
        catch (e) {

        }
    }


    return (

        <Card className={`w-[18rem] h-[32rem] overflow-hidden dark:bg-[#0f172a] bg-[#f1f2f3]`}>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none"
            >
            </CardHeader>
            <Link to={to} >
                <CardBody className='h-[26rem]  cursor-pointer'>
                    <Typography variant="h4" className='text-md dark:text-white mb-6 capitalize'>
                        {resourceName?.length > 40 ? resourceName?.slice(0, 40) + "......" : resourceName}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        subject :   {subject?.length > 40 ? subject?.slice(0, 40) + "......" : subject}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        branch : {branch}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        semester : {semester}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        scheme : {scheme}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        type : {type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        approval : {approval} (from admin)
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        likes : {formatNumber(likes?.length)}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        views : {formatNumber(views)}
                    </Typography>
                    <Typography variant="h6" className="mt-3 font-normal text-sm capitalize dark:text-white">
                        created At : {formatDate(createdAt)}
                    </Typography>
                </CardBody>
            </Link>
            <CardFooter className="flex items-center justify-between">
                <div className="flex items-center -space-x-3">
                    <Tooltip content={userEmail}>
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="natali craig"
                            src={profilePic}
                            referrerPolicy="no-referrer"
                            className="border-2 border-white hover:z-10"
                        />
                    </Tooltip>

                </div>
                <div className='flex flex-wrap gap-5'>
                    <div className='flex gap-3 items-center  cursor-pointer'>
                        <EditIcon className='dark:text-white' onClick={() => { edit(resourceName, type, id) }} />
                    </div>
                    <div className='flex gap-3 items-center  cursor-pointer'>
                        <DeleteIcon className='dark:text-white' onClick={() => { deleteCard(id) }} />
                    </div>
                </div>


            </CardFooter>
        </Card>
    )
}

export default ResourceCard
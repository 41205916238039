import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { Button as TailWindButton } from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { Select, Option } from "@material-tailwind/react";
import { useEffect } from 'react';
import { resourceType } from "../../constants/College";
import { Spinner } from "@material-tailwind/react";
import useAlert from "../../hooks/useAlert";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const DashboardFileUpload = ({ loadAgain, setLoadAgain }) => {


  const { getUserInfo } = useAuth()
  let { showAlert } = useAlert()


  const [resourceName, setResourceName] = React.useState();
  const [subject, setSubject] = React.useState([])
  const [semester, setSemester] = React.useState()
  const [scheme, setScheme] = React.useState()
  const [branch, setBranch] = React.useState()
  const [type, setType] = React.useState()
  const [branchesList, setBranchesList] = React.useState([])
  const [semestersList, setSemestersList] = React.useState([])
  const [schemeList, setSchemeList] = React.useState([])
  const [subjectList, setSubjectList] = React.useState([])
  const [send, setSend] = React.useState(false)
  const [playList, setPlayList] = React.useState()

  const theme = useSelector(state => state.theme)

  const [file, setFile] = React.useState()
  const [typeList] = React.useState(resourceType.filter((item, index) => getUserInfo().user.role === "admin" ? item : index != resourceType.length - 1))

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (type !== "videoLecture") {
      if (send === false && subject && resourceName && scheme && branch && semester && type && file) {
        setSend(true)
        try {
          const formData = new FormData()
          formData.append('file', file)
          const data = JSON.stringify({
            type,
            resourceName,
            subject,
            semester,
            scheme,
            branch
          })
          formData.append('data', data)

          await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/resource`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${getUserInfo().token}`
              }
            })
          showAlert("green", "Resource uploaded successfully..!", 5000)
          setSend(false)
          setLoadAgain(!loadAgain)
        }
        catch (e) {
          setSend(false)

          if (e?.response?.data?.message) {
            console.log(e.response)
            showAlert("red", e.response.data.message, 5000)
          } else {
            showAlert("red", "something went wrong...!", 5000)
          }

        }
      }
    }
    else {
      if (send === false && subject && resourceName && scheme && branch && semester && type && playList) {
        setSend(true)
        try {
          const data = {
            type,
            resourceName,
            subject,
            semester,
            scheme,
            branch,
            playListId: [extractPlaylistId(playList)]
          }
          await axios.post(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/uploads/playlist`,
            data,
            {
              headers: {
                Authorization: `Bearer ${getUserInfo().token}`
              }
            })
          showAlert("green", "Resource uploaded successfully..!", 5000)
          setSend(false)
          setLoadAgain(!loadAgain)
        }
        catch (e) {
          setSend(false)

          if (e?.response?.data?.message) {
            console.log(e.response)
            showAlert("red", e.response.data.message, 5000)
          } else {
            showAlert("red", "something went wrong...!", 5000)
          }

        }
      }
    }


  }

  const getConstant = async (path) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/${path}`,
        {
          headers: {
            Authorization: `Bearer ${getUserInfo().token}`
          }
        })
      return res
    }
    catch { }
  }

  const setConstants = async () => {
    try {
      let branchData = await getConstant("branches/branches");
      let branch = branchData.data.map((value) => {
        return value
      });
      setBranchesList(branch)
    } catch { }
  }

  const setSubjects = async (scheme, semester, branch) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_MSBTE_WALLAH_BACKEND}/branches/subjects`,
        {
          params: {
            semester: semester,
            branch: branch,
            scheme: scheme,
            limit: "all",
            page: "all"
          },
          headers: {
            Authorization: `Bearer ${getUserInfo().token}`
          }
        })
      setSubjectList(res.data)
    } catch { }

  }

  function extractPlaylistId(url) {
    // Regular expression to match the playlist ID
    const regex = /[?&]list=([^&]+)/;
    const match = regex.exec(url);

    // Check if there's a match and return the playlist ID
    if (match && match[1]) {
      return match[1];
    } else {
      return null; // Return null if no match found
    }
  }

  useEffect(() => {
    setConstants()
  }, [])


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      // Reset file state if an invalid file is selected
      setFile(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      // Reset file state if an invalid file is dropped
      setFile(null);
    }
  };


  return (
    <form onSubmit={handleFormSubmit} className='flex w-full h-full justify-evenly px-5 md:px-20 items-center flex-wrap'>

      <div className='flex w-full md:w-[60%] flex-col h-full gap-5 py-16'>



        <Input required size="lg" type='text' label="Resource Name" autoComplete='off' color={theme ? `gray` : 'white'} onChange={(e) => { setResourceName(e.target.value) }} />

        <div className="w-full">
          <Select required label="Type" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setType(e)
          }} className='capitalize' >
            {typeList.map((value, key) => <Option key={key} className='capitalize' value={value}>{value.replace(/([a-z])([A-Z])/g, '$1 $2')}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="Branch" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setBranch(e)
            for (let item of branchesList) {
              if (item._id === e) {
                setSemestersList(item.semesters)
                console.log(item.semesters)
                break;
              }
            }

            for (let item of branchesList) {
              if (item._id === e) {
                setSchemeList(item.schemes)
                console.log(item.semesters)
                break;
              }
            }

            if (scheme !== "" && semester !== "" && branch !== "") {
              setSubjects(scheme, semester, e)
            }


          }} className='capitalize' >
            {branchesList.map((value, key) => <Option key={key} className='capitalize' value={value._id}>{value.branchName}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="Semester" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setSemester(e)
            if (scheme !== "" && semester !== "" && branch !== "") {
              setSubjects(scheme, e, branch)
            }
          }} className='capitalize' >
            {semestersList.map((value, key) => <Option className='capitalize' key={key} value={value}>{value}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="scheme" style={{ color: theme ? "gray" : "white" }} onChange={(e) => {
            setScheme(e)
            if (scheme !== "" && semester !== "" && branch !== "") {
              setSubjects(e, semester, branch)
            }
          }} className='capitalize' >
            {schemeList.map((value, key) => <Option className='capitalize' key={key} value={value}>{value}</Option>)}
          </Select>
        </div>

        <div className="w-full">
          <Select required label="Subject" style={{ color: theme ? "gray" : "white" }} onChange={(e) => { setSubject(e) }} className='capitalize' >
            {subjectList.map((value, key) => <Option key={key} className='capitalize' value={value._id}>{value.name}</Option>)}
          </Select>
        </div>
        <div className={`${type == "videoLecture" ? "" : "hidden"}`} >
          <Input size="lg" type='text' label="Playlist" autoComplete='off' color={theme ? `gray` : 'white'} onChange={(e) => { setPlayList(e.target.value) }} />
        </div>

      </div>
      <div className={`flex justify-center flex-col gap-5 mb-10 md:mb-0 items-center w-full md:w-[40%] ${type == "videoLecture" ? "hidden" : ""}`}>
        <Typography className='font-bold text-center dark:text-white text-black'>{file?.name.length > 50 ? file?.name.substr(0, 50) : file?.name}</Typography>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{ width: 'fit-content' }} // Adjust width as needed
        >
          <Button
            component="label"
            variant="contained"
            className='bg-[#3063f1]'
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <input
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </Button>
        </div>
      </div>
      <div className='flex w-full justify-center md:justify-end'>
        <TailWindButton className="capitalize px-7 h-[3rem]  w-[8rem] bg-[#3063F1] text-sm flex items-center justify-center" type='submit'> {send ? <Spinner className="h-6 w-7 text-white" /> : "upload"}</TailWindButton>
      </div>
    </form>
  );
}

export default DashboardFileUpload